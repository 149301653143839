import React, { useState } from 'react'
import { baseURL } from '../../Axios'
import { toast } from 'react-toastify'

export default function Form(props) {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [subject, setSubject] = useState('')
  const [company, setCompany] = useState('')
  const [message, setMessage] = useState('')

  const submit = async () => {
    const url = props.info == 'Yarn' ?
      `${baseURL}api/yarn-inquiry` :
      props.info == 'Home Textile' ?
        `${baseURL}api/hometextile-inquiry` :
        props.info == 'Fabric' ?
          `${baseURL}api/fabric-inquiry` :
          props.info == 'Garments' ?
            `${baseURL}api/garment` :
            props.info == 'general-inquiry' ?
              `${baseURL}api/general-inquiry` :
              props.info == 'contact-us' ?
                `${baseURL}api/contact-us` :
                `${baseURL}api/general-inquiry`;

    const form = (props.info == 'Yarn' || props.info == 'Fabric') ?
     {
      "name": name,
      "email": email,
      "phone": phone,
      "company": company,
      "message": message,
    } :
    {
      "name": name,
      "email": email,
      "phone": phone,
      "subject": subject,
      "message": message,
    } ;

    console.log('my form data', name, form)
    const res = await fetch(url, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(form)
    }).then((res) => res.json())
      .then((json) => {
        console.log(json)
        toast.success("Form Submitted Successfully !", {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      })
      .catch(err => console.log('error', err))
  }

  const inputField = {
    width: "100%",
    padding: "10px 15px 10px 15px",
    marginRight: "25px",
    fontSize: "20px"
  };

  const listContent = {
    color: "white",
    fontSize: "22px",
    textAlign: "justify"
  };

  const bullet = { color: "#00AFF0", fontSize: "20px" }

  const bg = { height: "100%", backgroundImage: `linear-gradient(rgba(40, 40, 40, 0.8), rgba(21, 21, 21, 0.9)),url(${props.image})`, backgroundSize: "cover", padding: "60px 40px 45px 40px" }

  const simpleBg = { height: "100%", backgroundImage: `url(${props.image})`, backgroundSize: "cover", padding: "50px" }

  return (
    <>
      <div className="container proform-inner-padding">
        <div className="row fabric-row">
          <div className="col-md-6 p-0">
            {props.inquiryForm ? (
              <>
                <div style={{ height: "100%" }}>
                  <div style={bg}>
                    <h1 className='text-white fw-bold bordered_heading_white'>Required {props.info} Information</h1>
                    <br /><br />
                    {props.info == "Yarn" ? (
                      <ul className='list-group'>
                        <li style={{ listStyleType: "none" }}>
                          <div style={{ display: "inline-block" }}>
                            <i class="fa fa-check-circle" style={bullet}></i>
                          </div>
                          <span style={listContent}>
                            <strong>Technical Specification: </strong>
                            Count, (fiber) Blend, Rkm, Tpm required. If you don’t have this information you can also send us your sample we will examine in our lab and quote compatible price.
                          </span>
                        </li>

                        <br />

                        <li style={{ listStyleType: "none" }}>
                          <div style={{ display: "inline-block" }}>
                            <i class="fa fa-check-circle" style={bullet}></i>
                          </div>
                          <span style={listContent}>
                            <strong>Required Form: </strong>
                            Bleached, fiber Dyed, Yarn Dye (Other additional Details)
                          </span>
                        </li>

                        <br />

                        <li style={{ listStyleType: "none" }}>
                          <div style={{ display: "inline-block" }}>
                            <i class="fa fa-check-circle" style={bullet}></i>
                          </div>
                          <span style={listContent}>
                            <strong>Required Packing: </strong>
                            Paper Cone, Dye Cone, Dye Tubes and hanks with Carton/ Pallets packing
                          </span>
                        </li>

                        <br />

                        <li style={{ listStyleType: "none" }}>
                          <div style={{ display: "inline-block" }}>
                            <i class="fa fa-check-circle" style={bullet}></i>
                          </div>
                          <span style={listContent}>
                            Delivery Port, Required Quantity and Payment Terms.
                          </span>
                        </li>
                      </ul>
                    ) : props.info == "Fabric" ? (
                      <ul className='list-group'>
                        <li style={{ listStyleType: "none" }}>
                          <div style={{ display: "inline-block" }}>
                            <i class="fa fa-check-circle" style={bullet}></i>
                          </div>
                          <span style={listContent}>
                            <strong>Quality: </strong>
                            &nbsp; Count, Construction, (GSM), width required. If you don’t have this information you can also send us your sample we will examine in our lab and quote compatible price.
                          </span>
                        </li>

                        <br />

                        <li style={{ listStyleType: "none" }}>
                          <div style={{ display: "inline-block" }}>
                            <i class="fa fa-check-circle" style={bullet}></i>
                          </div>
                          <span style={listContent}>
                            <strong>Required Form: </strong>
                            Grey, Bleached, Dyed, (Other additional Details)
                          </span>
                        </li>

                        <br />

                        <li style={{ listStyleType: "none" }}>
                          <div style={{ display: "inline-block" }}>
                            <i class="fa fa-check-circle" style={bullet}></i>
                          </div>
                          <span style={listContent}>
                            <strong>Required Packing: </strong>
                            Rolls, Pallets, Bales (In case of finished Products send us your requirement in detail)
                          </span>
                        </li>

                        <br />

                        <li style={{ listStyleType: "none" }}>
                          <div style={{ display: "inline-block" }}>
                            <i class="fa fa-check-circle" style={bullet}></i>
                          </div>
                          <span style={listContent}>
                            Delivery Port, Required Quantity and Payment Terms.
                          </span>
                        </li>
                      </ul>
                    ) : false}
                  </div>
                </div>
              </>) : <div className='d-none d-md-block' style={simpleBg}></div>}
          </div>
          <div className="col-md-6" style={{ background: "#00ABE4" }}>
            <div className='proform'>
              <h1 className='text-white fw-bold bordered_heading_white'>{props.tag}</h1>
              <br /><br />
              <div className="row">
                <div className="col-md-6">
                  <input value={name} onChange={val => setName(val.target.value)} type="text" style={inputField} placeholder='Name' />
                  <br /><br />
                </div>
                <div className="col-md-6 mb-4">
                  <input value={email} onChange={val => setEmail(val.target.value)} type="text" style={inputField} placeholder='Email' />
                </div>
                <div className="col-md-6 mb-4">
                  <input value={phone} type="text" onChange={val => setPhone(val.target.value)} placeholder='Mobile Number' style={inputField} />
                </div>
                { (props.info == 'Yarn' || props.info == 'Fabric') ?
                <div className="col-md-6 mb-4">
                  <input value={company} type="text" onChange={val => setCompany(val.target.value)} placeholder='Company' style={inputField} />
                  <br />
                </div> :
                <div className="col-md-6 mb-4">
                  <input value={subject} type="text" onChange={val => setSubject(val.target.value)} placeholder='Subject' style={inputField} />
                  <br />
                </div>
                }
                <div className="col-md-12">
                  <textarea value={message} onChange={val => setMessage(val.target.value)} placeholder='Your Message Here' style={{ padding: "10px 15px 10px 15px", fontSize: "19px", width: "100%" }} rows="3"></textarea>
                </div>
              </div>
              <br />
              <button onClick={submit} className='btn btn-light px-4 py-3 text-info'>SUBMIT NOW</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
