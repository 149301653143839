import React from "react";
import "./Products.css";
import 'react-slideshow-image/dist/styles.css'
import { Fade } from 'react-slideshow-image';

export default function FabricCard(props) {
 
  const short_title = {
    color: "#595959",
    fontSize: "19px",
    fontWeight: "bold",
    marginBottom: "10px",
    display: "block",
    textAlign:props.image?.length ? 'left' : 'center'
  };
   return (
    <div className="row fabric-row" id={props.abc} >
      {props.ltr ? (
        <div className="col-12 col-lg-6 col-md-6 p-3 fabric-col-text" style={{ display: "flex", alignItems: "center" }} data-aos="fade-in" data-aos-duration="4000" data-aos-once="true">
          {
            props?.image?.length <= 1 ?
              <img
                className="slide fabric-img"
                src={props.image && props?.image[0]?.images}
                alt=""
                style={{ width: "100%" }}
              /> :
              props?.image?.length > 0 ?
              <Fade style={{ height: '100%', width: '100%' }} duration={5000} arrows={false} transitionDuration={1000}  >
                {props?.image?.map((image) => {
                  return (
                    <img
                      className="slide fabric-img"
                      src={image?.images}
                      alt=""
                      style={{ width: "100%" }}
                    />
                  )
                })

                }
              </Fade> : null
          }
        </div>
      ) : (
        false
      )}
      <div className={props.image?.length ? "col-md-6" : "col-md-12"} style={{ display: "flex",justifyContent:props.image?.length ? 'flex-start' : 'center', alignItems: "center" }} data-aos="zoom-in" data-aos-duration="4000" data-aos-once="true">
        <div style={{textAlign: props.image?.length ? 'left' : 'center'}} >
        { props.proFab ? 
          <span style={short_title}>Our Products</span> : null
        }
          <h1 className="bordered_heading fabricCard-main-title" style={{textAlign: props.image?.length ? 'left' : 'center'}}  >
            {props?.title}
          </h1>
          {props?.html}
        </div>
      </div>
      {props?.rtl ? (
        <div className="col-md-6 p-3 fabric-col-img" style={{ display: "flex", alignItems: "center" }} data-aos="fade-in" data-aos-duration="4000" data-aos-once="true">
          {
            props?.image?.length <= 1 ?
              <img
                className="slide fabric-img"
                src={props?.image && props?.image[0]?.images}
                alt=""
                style={{ width: "100%" }}
              /> :
              props?.image?.length > 0 ?
              <Fade className="fabric-img" style={{ height: '100%', width: '100%' }} duration={5000} arrows={false} transitionDuration={1000}  >
                {props?.image?.map((image) => {
                  return (
                    <img
                      className="slide fabric-img"
                      src={image?.images}
                      alt=""
                      style={{ width: "100%" }}
                    />
                  )
                })

                }
              </Fade> : null
          }
        </div>
      ) : (
        false
      )}
    </div>
  );
}
