import React, { useEffect, useRef, useState } from "react";
import Header from "../../Components/Header";
import NavigationHeader from "../../Components/NavigationHeader";
import ProForm from "./ProForm";
import FabricCard from "./FabricCard";
import QualityCard from "./QualityCard";
import TypeWriterEffect from "react-typewriter-effect";
import AxiosInstance from "../../Axios";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from 'react-toastify';
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
export default function Product() {

  const short_title = {
    color: "#595959",
    fontSize: "19px",
    fontWeight: "bold",
    marginBottom: "10px",
    display: "block",
  };
  const bullet = {
    color: "#00AFF0",
    fontSize: "20px",
  };

  const listContent = {

  };
  const route = useLocation();
  const params = useParams()

  const [post, setPost] = useState({});
  const [subPosts, setSubPosts] = useState([]);
  const [belowPoints, setBelowPoints] = useState([]);
  const ProductReducer = useSelector(state => state.ProductReducer)

  useEffect(() => {
    window.scrollTo(0, 0)
    const product = ProductReducer?.find((product, pIndex) => product?.id == params?.id)
    console.log('thissssss',product)
    document.title = `${params?.title} - Skytex`;
    setPost(product)
    if (product?.title == 'Yarn' || product?.title == 'yarn' || product?.id == 39) {
      AxiosInstance.get(`api/yarn-points`)
        .then((res) => {
          setBelowPoints(res?.data?.successData?.data[0]);
        })
        .catch((error) => {
          //  console.log(error.message);
        });
      setSubPosts([]);
    } else {
      setSubPosts(product?.categories);
    }
  }, [ProductReducer,route]);

  function lastWord(words) {
    var n = words.split(/[\s:]+/);
    return n[n.length - 1];
  }
  const ref = useRef();
  return (
    <>
      <Helmet>
        <title></title>
      </Helmet>
      <Header />
      {/* <button title={'click'} onClick={() => {}} /> */}
      <section>
        <NavigationHeader
          page={post?.title}
          reqBtn
          background={post?.header_image}
        />
        <br />
        <br />
        <div className="container fabric-order">
          <FabricCard
            proFab={true}
            title={post?.title}
            rtl
            image={post?.images}
            html={
              <>
                {
                  post?.productdescriptions?.map((desc) => {
                    return (
                      <>
                        <p>
                          {desc?.description}
                        </p>
                      </>
                    )
                  })
                }
                {
                  post?.points?.map((item) => {
                    return (
                      <>
                        <p className="fw-bold">
                          {item?.title1}
                        </p>
                        <ul className="list-group">
                          {
                            item?.subpoints?.map((subPoint) => {
                              const stringArr = subPoint?.points?.split(':')
                              return (
                                <li style={{ listStyleType: "none" }}>
                                  <div style={{ display: "inline-block" }}>
                                    <i class="fa fa-check-circle" style={bullet}></i>
                                  </div>
                                  {
                                    stringArr == null ?
                                      null :
                                      stringArr.length < 2 ?
                                        <span style={listContent}>
                                          {/* {lastWord(post.points)} */}
                                          <span>{stringArr[0]}</span>
                                          {subPoint?.description != null && subPoint?.description != 'null' ?
                                            <p className="mt-3">
                                              {subPoint?.description}
                                            </p>
                                            :
                                            null
                                          }
                                        </span>
                                        :
                                        <span style={listContent}>
                                          <strong>{stringArr[0]}: </strong>
                                          {/* {lastWord(post.points)} */}
                                          <span>{stringArr[1]}</span>
                                          {subPoint?.description != null && subPoint?.description != 'null' ?
                                            <p className="mt-3">
                                              {subPoint?.description}
                                            </p>
                                            :
                                            null
                                          }
                                        </span>
                                  }
                                </li>
                              )
                            })
                          }
                        </ul>
                      </>
                    )
                  })
                }
              </>
            }
          />

          {
            subPosts?.map((subPost, index) => {
              const arrayStr1 = subPost?.title?.split(' ');
              return (
                <>
                  {
                    post?.id == 36 && index == 1 ?
                      <>
                        <br />
                        <br />
                        <div className="fabric-row">
                          <h2
                            className="animatedwords fw-bold text-center mt-3 fabric-animate"
                            style={{ color: "#595959" }}
                          >
                            All qualities are available in &nbsp;
                            <TypeWriterEffect
                              textStyle={{
                                color: "#00AFF0",
                                fontWeight: 700,
                                fontSize: "1.0em",
                                display: "inline-block",
                              }}
                              startDelay={1000}
                              cursorColor="#FFFFFF"
                              multiText={["  Bleached", " Printed", " Dyed"]}
                              multiTextDelay={2000}
                              multiTextLoop
                              typeSpeed={150}
                            />
                            &nbsp; Form
                          </h2>
                        </div>
                        <br />
                        <br />
                      </>
                      : null
                  }
                  <FabricCard
                    abc={arrayStr1 ? arrayStr1?.length > 1 ? `${arrayStr1[0]}-${arrayStr1[1]}` : `${arrayStr1[0]}` : null}
                    title={subPost?.title}
                    rtl={index < 1 ? index % 2 == 1 ? true : false : true}
                    ltr={index < 1 ? index % 2 == 0 ? true : false : false}
                    image={subPost?.images}
                    html={
                      <>

                        {
                          subPost?.productdescriptions?.map((desc) => {
                            return (
                              <>
                                <p>
                                  {desc.description}
                                </p>
                              </>
                            )
                          })
                        }
                        {
                          subPost?.points?.map((item) => {
                            return (
                              <>
                                <p className="fw-bold mt-2">
                                  {item.title1}
                                </p>
                                <ul style={{ padding: 0 }} >
                                  {
                                    item.subpoints?.map((subPoint) => {
                                      const stringArr = subPoint?.points?.split(':')

                                      return (
                                        <li style={{
                                          listStyleType: "none",
                                          float: 'left',
                                          width: item.subpoints?.length > 10 ? '50%' : '100%',
                                          display: item.subpoints?.length > 10 ? 'inline-block' : 'block'
                                        }}>
                                          <div style={{ display: "inline-block" }}>
                                            <i class="fa fa-check-circle" style={bullet}></i>

                                          </div>
                                          {
                                            stringArr == null ?
                                              null :
                                              stringArr.length < 2 ?
                                                <span style={listContent}>
                                                  {/* {lastWord(post.points)} */}
                                                  <span>{stringArr[0]}</span>
                                                  {subPoint.description != null && subPoint.description != 'null' ?
                                                    <p className="mt-3">
                                                      {subPoint.description}
                                                    </p>
                                                    :
                                                    null
                                                  }
                                                </span>
                                                :
                                                <span style={listContent}>
                                                  <strong>{stringArr[0]}: </strong>
                                                  {/* {lastWord(post.points)} */}
                                                  <span>{stringArr[1]}</span>
                                                  {subPoint.description != null && subPoint.description != 'null' ?
                                                    <p className="mt-3">
                                                      {subPoint.description}
                                                    </p>
                                                    :
                                                    null
                                                  }
                                                </span>

                                          }

                                        </li>
                                      )
                                    })
                                  }
                                </ul>

                              </>
                            )
                          })
                        }
                      </>
                    }
                  />
                </>
              )
            })
          }
          <br />
          {(post?.title == 'Yarn' || post?.title == 'yarn' || post?.id == 39) ?

            <div className="row fabric-row">
              <div className="col-md-12" >

                <h1 data-aos="zoom-in" data-aos-duration="4000" data-aos-once="true" style={{ fontSize: "28px" }}>
                  <strong>{belowPoints?.title1}:</strong>
                </h1>
                <p data-aos="zoom-in" data-aos-duration="4000" data-aos-once="true">
                  {belowPoints?.description1}
                </p>
                <br />
                <h1 data-aos="zoom-in" data-aos-duration="4000" data-aos-once="true" style={{ fontSize: "28px" }}>
                  <strong>{belowPoints?.title2}:</strong>
                </h1>
                <p data-aos="zoom-in" data-aos-duration="4000" data-aos-once="true">
                  {belowPoints?.description2}
                </p>

                <br />
                <ul className="list-group">
                  <li data-aos="zoom-in" data-aos-duration="4000" data-aos-once="true" style={{ listStyleType: "none" }}>
                    <div style={{ display: "inline-block" }}>
                      <i class="fa fa-check-circle" style={bullet}></i>
                    </div>
                    <span style={listContent}>
                      <strong>{belowPoints?.title3}: </strong>
                      {belowPoints?.description3}
                    </span>
                  </li>
                  <li data-aos="zoom-in" data-aos-duration="4000" data-aos-once="true" className="mt-2" style={{ listStyleType: "none" }}>
                    <div style={{ display: "inline-block" }}>
                      <i class="fa fa-check-circle" style={bullet}></i>
                    </div>
                    <span style={listContent}>
                      <strong>{belowPoints?.title4}: </strong>
                      {belowPoints?.description4}
                    </span>
                  </li>
                </ul>
              </div>
            </div> :

            null
          }
        </div>
        <br />


        <br />
        <div id={'abc'} className="container-fluid" style={{ background: "#F4FCFF" }}>
          <div className="container-fluid">
            <div>
              <ProForm
                proId={post?.id}
                inquiryForm={post?.id == 37 || post?.id == 38 ? false : true}
                info={post?.title}
                tag={post?.id == 37 || post?.id == 38 ? "Your Inquiry" : "Send us your inquiry we will quote you best price"}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
