import React, { useEffect, useState } from 'react'
import { baseURL } from '../../Axios'
import AxiosInstance from '../../Axios'
import { toast } from 'react-toastify'

export default function ProForm(props) {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [company, setCompany] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [formDesc, setFormDesc] = useState(null)

  const submit = async () => {
    const url = props.info == 'Yarn' ?
      `${baseURL}api/yarn-inquiry` :
      props.info == 'Home Textile' ?
        `${baseURL}api/hometextile-inquiry` :
        props.info == 'Fabric' ?
          `${baseURL}api/fabric-inquiry` :
          props.info == 'Garments' ?
            `${baseURL}api/garment` :
            props.info == 'general-inquiry' ?
              `${baseURL}api/general-inquiry` :
              props.info == 'contact-us' ?
                `${baseURL}api/contact-us` :
                `${baseURL}api/general-inquiry`;

                const form = (props.info == 'Yarn' || props.info == 'Fabric') ?
                {
                 "name": name,
                 "email": email,
                 "phone": phone,
                 "company": company,
                 "message": message,
               } :
               {
                 "name": name,
                 "email": email,
                 "phone": phone,
                 "subject": subject,
                 "message": message,
               } ;

    console.log('my form data', name, form)
    const res = await fetch(url, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(form)
    }).then((res) => res.json())
      .then((json) => {
        console.log(json)
        toast.success("Form Submitted Successfully !", {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      })
      .catch(err => console.log('error', err))
  }

  const apiCall = () => {
    if (props.info == 'Yarn') {
      AxiosInstance.get(`api/requirement-yarn`)
        .then((res) => {
          const a = res.data.successData.data;
          setFormDesc(a[0]);
        })
        .catch((error) => {
          //  console.log(error.message);
        })
    } else if (props.info == "Fabric") {
      AxiosInstance.get(`api/requirement-fabric`)
        .then((res) => {
          const a = res.data.successData.data;
          setFormDesc(a[0]);
        })
        .catch((error) => {
          //  console.log(error.message);
        })
    } else {
      setFormDesc();
    }
  }

  useEffect(() => {
    apiCall();
  }, [props.info]);



  const inputField = {
    width: "100%",
    padding: "10px 15px 10px 15px",
    marginRight: "25px",
    fontSize: "20px"
  };

  const listContent = {
    color: "white",
    fontSize: "22px",
    textAlign: "justify"
  };

  const bullet = { color: "#00AFF0", fontSize: "20px" }

  const bg = { height: "100%", 
  backgroundImage: 
  props.proId==39 ?
  `linear-gradient(rgba(40, 40, 40, 0.8), rgba(21, 21, 21, 0.9)),url(/media/OnlineInquiry/yarn-inquiry-form-bg.jpg)` :
  props.proId==37 ? 
  `linear-gradient(rgba(40, 40, 40, 0.8), rgba(21, 21, 21, 0.9)),url(/media/Products/Home-Textile/home-textile-inquiry-bg.jpg)` :
  props.proId==38 ? 
  `linear-gradient(rgba(40, 40, 40, 0.8), rgba(21, 21, 21, 0.9)),url(/media/Products/Garments/inquiry-form-bg.jpg)` :
  `linear-gradient(rgba(40, 40, 40, 0.8), rgba(21, 21, 21, 0.9)),url(/media/OnlineInquiry/fabric-form-bg.jpg)`
  , backgroundSize: "cover", padding: "60px 40px 45px 40px" }

  const simpleBg = { height: "100%", 
  backgroundImage:
   props.proId==39 ?
  `url(/media/OnlineInquiry/yarn-inquiry-form-bg.jpg)` :
  props.proId==37 ? 
  `url(/media/Products/Home-Textile/home-textile-inquiry-bg.jpg)` :
  props.proId==38 ? 
  `url(/media/Products/Garments/inquiry-form-bg.jpg)` :
  `url(/media/OnlineInquiry/fabric-form-bg.jpg)`
  
   , backgroundSize: "cover", padding: "50px" }

  return (
    <>
      <div className="container proform-inner-padding">
        <div className="row fabric-row">
          <div className="col-md-6 p-0">
            {props.inquiryForm ? (
              <>
                <div style={{ height: "100%" }}>
                  <div style={bg}>
                    <h1 className='text-white fw-bold bordered_heading_white'>{formDesc?.title}</h1>
                    <br /><br />
                    {props.info == "Yarn" ? (
                      <ul className='list-group'>
                        {
                          formDesc?.requirementpoints?.map((item) => {
                            const stringArr = item?.point?.split(':')
                            return (
                              <li style={{ listStyleType: "none", marginBottom: "14px" }}>
                                <div style={{ display: "inline-block" }}>
                                  <i class="fa fa-check-circle" style={bullet}></i>
                                </div>
                                {
                                  stringArr == null ?
                                    null :
                                    stringArr.length < 2 ?
                                      <span style={listContent}>
                                        {/* {lastWord(post.points)} */}
                                        <span>{stringArr[0]}</span>
                                      </span>
                                      :
                                      <span style={listContent}>
                                        <strong>{stringArr[0]}: </strong>
                                        {/* {lastWord(post.points)} */}
                                        <span>{stringArr[1]}</span>
                                      </span>
                                }
                              </li>
                            )
                          })
                        }
                      </ul>
                    ) : props.info == "Fabric" ? (
                      <ul className='list-group'>
                        {
                          formDesc?.requirementpoints?.map((item) => {
                            const stringArr = item?.point?.split(':')
                            return (
                              <li style={{ listStyleType: "none", marginBottom: "14px" }}>
                                <div style={{ display: "inline-block" }}>
                                  <i class="fa fa-check-circle" style={bullet}></i>
                                </div>
                                {
                                  stringArr == null ?
                                    null :
                                    stringArr.length < 2 ?
                                      <span style={listContent}>
                                        {/* {lastWord(post.points)} */}
                                        <span>{stringArr[0]}</span>
                                      </span>
                                      :
                                      <span style={listContent}>
                                        <strong>{stringArr[0]}: </strong>
                                        {/* {lastWord(post.points)} */}
                                        <span>{stringArr[1]}</span>
                                      </span>
                                }
                              </li>
                            )
                          })
                        }
                      </ul>
                    ) : false}
                  </div>
                </div>
              </>) : <div className='d-none d-md-block' style={simpleBg}></div>}
          </div>
          <div className="col-md-6" style={{ background: "#00ABE4" }}>
            <div className='proform'>
              <h1 className='text-white fw-bold bordered_heading_white'>{props.tag}</h1>
              <br /><br />
              <div className="row">
                <div className="col-md-6">
                  <input value={name} onChange={val => setName(val.target.value)} type="text" style={inputField} placeholder='Name' />
                  <br /><br />
                </div>
                <div className="col-md-6 mb-4">
                  <input value={email} onChange={val => setEmail(val.target.value)} type="text" style={inputField} placeholder='Email' />
                </div>
                { (props.info == 'Yarn' || props.info == 'Fabric') ?
                <div className="col-md-6 mb-4">
                  <input value={company} type="text" onChange={val => setCompany(val.target.value)} placeholder='Company' style={inputField} />
                  <br />
                </div> :
                <div className="col-md-6 mb-4">
                  <input value={subject} type="text" onChange={val => setSubject(val.target.value)} placeholder='Subject' style={inputField} />
                  <br />
                </div>
                }
                <div className="col-md-6 mb-4">
                  <input value={phone} type="text" onChange={val => setPhone(val.target.value)} placeholder='Mobile Number' style={inputField} />
                </div>
                <div className="col-md-12">
                  <textarea value={message} onChange={val => setMessage(val.target.value)} placeholder='Your Message Here' style={{ padding: "10px 15px 10px 15px", fontSize: "19px", width: "100%" }} rows="3"></textarea>
                </div>
              </div>
              <br />
              <button onClick={submit} className='btn btn-light px-4 py-3 text-info'>SUBMIT NOW</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
