import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import NavigationHeader from "../../Components/NavigationHeader";
import ProForm from "./ProForm";
import AxiosInstance from "../../Axios";
import { Helmet } from "react-helmet";
import 'react-slideshow-image/dist/styles.css'
import { Fade } from 'react-slideshow-image';

export default function Cellulose() {
  const [posts, setPosts] = useState([]);
  const [belowPoints, setBelowPoints] = useState([]);

  useEffect(() => {
    AxiosInstance.get(`api/product-category/39`)
      .then((res) => {
        setPosts(res.data.successData.category);
      })
      .catch((error) => {
        //  console.log(error.message);
      });
    AxiosInstance.get(`api/count-range`)
      .then((res) => {
        setBelowPoints(res.data.successData.data[0]);
      })
      .catch((error) => {
        //  console.log(error.message);
      });
  }, []);

  const short_title = {
    color: "#595959",
    fontSize: "19px",
    fontWeight: "bold",
    marginBottom: "10px",
    display: "block",
  };

  const main_title = {
    fontSize: "46px",
    fontWeight: "bold",
    color: "#595959",
  };

  const listContent = {
    color: "#444444",
    fontSize: "22px",
    textAlign: "justify",
  };

  const bullet = {
    color: "#00AFF0",
    fontSize: "20px",
  };

  return (
    <>
      <Helmet>
        <title>Cellulose (100% & Blended Yarn) - Skytex</title>
      </Helmet>
      <section>
        <Header />
        <NavigationHeader
          page="Cellulose (100% & Blended Yarn)"
          reqBtn
          background={"/media/Products/Yarn/yarn-product-bg.jpg"}
        />
        <br />
        <br />
        <br />
        <div className="container">
          {
            posts?.filter((item) => item.id == 103)?.map((item) => {

              return (
                <div className="row fabric-row">
                  <div className="col-md-6" data-aos="zoom-in" data-aos-duration="4000" data-aos-once="true">

                    <h1 className="bordered_heading" style={main_title}>
                      {item.title}
                    </h1>
                    <br />
                    <br />

                    {item?.points?.map((point) => (

                      <>
                      { point?.title1 == null || point?.title1=='null' ? null :
                        <p className="fw-bold mt-4">
                          {point?.title1}
                        </p>
            }
                       <ul className="list-group">
                          {
                            point?.subpoints?.map((subPoint) => {
                              const stringArr = subPoint.points?.split(':')

                              return (


                                <li style={{ listStyleType: "none" }}>
                                  <div
                                    style={{ display: "inline-block" }}
                                  >
                                    <i class="fa fa-check-circle" style={bullet}></i>

                                  </div>

                                  {
                                    stringArr == null ?
                                      null :
                                      stringArr.length < 2 ?
                                        <span style={listContent}>
                                          {/* {lastWord(post.points)} */}
                                          <span>{stringArr[0]}</span>
                                          {subPoint.description != null && subPoint.description != 'null' ?
                                            <p className="mt-3">
                                              {subPoint.description}
                                            </p>
                                            :
                                            null
                                          }
                                        </span>
                                        :
                                        <span style={listContent}>
                                          <strong>{stringArr[0]}: </strong>
                                          {/* {lastWord(post.points)} */}
                                          <span>{stringArr[1]}</span>
                                          {subPoint.description != null && subPoint.description != 'null' ?
                                            <p className="mt-3">
                                              {subPoint.description}
                                            </p>
                                            :
                                            null
                                          }
                                        </span>

                                  }

                                </li>

                              )
                            })
                          }
                        </ul>
                      </>

                    ))
                    }

                    <br />
                  </div>
                  <div className="col-md-6 col-lg-6 pe-0" data-aos="fade-in" data-aos-duration="4000" data-aos-once="true" style={{ display: "flex", alignItems: "center" }}>
                   
<div>
                          {item?.images?.map((image) => {
                            return (
                              <img
                                className="slide fabric-img mb-4"
                                src={image.images}
                                alt=""
                                style={{ width: "100%" }}
                              />
                            )
                          })

                          }
                          </div>

                  </div>
                </div>

              )
            })

          }



          {/* <div className="row fabric-row mt-4" data-aos="zoom-in" data-aos-duration="4000" data-aos-once="true">
            <h1 className="mb-2" style={{ fontSize: "27px" }}>
              <strong>
                Special yarn also available as per customer requirement:
              </strong>
            </h1>
            <div className="col-lg-4">
              <p style={listContent}>
                <i class="fa fa-check-circle" style={bullet}></i>
                <span className="ms-s"> Viloft</span>
              </p>
              <p style={listContent}>
                <i class="fa fa-check-circle" style={bullet}></i>
                <span className="ms-s"> Viscose Ecovero</span>
              </p>
              <p style={listContent}>
                <i class="fa fa-check-circle" style={bullet}></i>
                <span className="ms-s"> Soyabean</span>
              </p>
            </div>
            <div className="col-lg-4">
              <p style={listContent}>
                <i class="fa fa-check-circle" style={bullet}></i>
                <span className="ms-s"> Viseta</span>
              </p>
              <p style={listContent}>
                <i class="fa fa-check-circle" style={bullet}></i>
                <span className="ms-s"> Refibra</span>
              </p>

              <p style={listContent}>
                <i class="fa fa-check-circle" style={bullet}></i>
                <span className="ms-s"> Pro Modal</span>
              </p>
            </div>
            <div className="col-lg-4">
              <p style={listContent}>
                <i class="fa fa-check-circle" style={bullet}></i>
                <span className="ms-s"> Pro Modal</span>
              </p>
              <p style={listContent}>
                <i class="fa fa-check-circle" style={bullet}></i>
                <span className="ms-s"> Antibacterial (Air Cell)</span>
              </p>

              <p style={listContent}>
                <i class="fa fa-check-circle" style={bullet}></i>
                <span className="ms-s"> Viseta</span>
              </p>
            </div>
          </div> */}
          <br></br>

          <div data-aos="zoom-in" data-aos-duration="4000" data-aos-once="true">
            <h2 style={{ fontSize: "28px" }}>
              <strong>{belowPoints?.title}:</strong>
            </h2>
            <p>
              {belowPoints?.description}
            </p>
          </div>
          <h1 data-aos="zoom-in" data-aos-duration="4000" data-aos-once="true" style={{ fontSize: "28px" }}>
            <strong>{belowPoints?.title1}:</strong>
          </h1>
          <p data-aos="zoom-in" data-aos-duration="4000" data-aos-once="true">
            {belowPoints?.description1}
          </p>
        </div>

        <div>
          <br />
        </div>
        <br />
        <div className="container-fluid" style={{ background: "#F4FCFF" }}>
          <div>
            <ProForm
              image="/media/OnlineInquiry/yarn-inquiry-form-bg.jpg"
              inquiryForm
              apiType={'yarn'}
              info="Yarn"
              tag="Send us your inquiry we will quote you best price"
            />
          </div>
        </div>
      </section>
    </>
  );
}
