import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import NavigationHeader from "../../Components/NavigationHeader";
import Form from "../Contact/Form";
import FabricCard from "./FabricCard";
import QualityCard from "./QualityCard";
import TypeWriterEffect from "react-typewriter-effect";
import AxiosInstance from "../../Axios";
import { Helmet } from "react-helmet";

export default function HomeTextile() {
  document.title = "Home Textile - Skytex";
  const [posts, setPosts] = useState([]);
  const [subPosts, setSubPosts] = useState([]);


  const listContent = {
    color: "#444444",
    fontSize: "22px",
    textAlign: "justify",
  };

  const bullet = {
    color: "#00AFF0",
    fontSize: "20px",
  };

  useEffect(() => {
    AxiosInstance.get(`api/product`)
      .then((res) => {
        setPosts(res.data.successData.data);
      })
      .catch((error) => {
        //  console.log(error.message);
      });
    AxiosInstance.get(`api/product-category/37`)
      .then((res) => {
        setSubPosts(res.data.successData.category);
      })
      .catch((error) => {
        //  console.log(error.message);
      });
  }, []);

  function lastWord(words) {
    var n = words.split(/[\s:]+/);
    return n[n.length - 1];
  }

  return (
    <>
      <Helmet>
        <title>Home-Textile-Skytex</title>
      </Helmet>
      <Header />

      {posts?.filter((post) => post.id == 37)
        .map((post) => (
          <section>
            <NavigationHeader
              page={post.title}
              reqBtn
              background="/media/Products/Home-Textile/hometextile-bg.jpg"
            />
            <br />
            <br />
            <div className="container hometextile-order">
              <FabricCard
                title={post?.title}
                proFab={true}
                rtl
                image={post?.images}
                html={
                  <>
                    <br />
                    <br />
                    {
                      post?.productdescriptions?.map((desc) => {
                        return (
                          <>
                            <p>
                              {desc.description}
                              {/* We are supplying premium quality yarn from Pakistan, India, China, Turkey, Egypt, Vietnam, and Indonesia. */}
                            </p>

                          </>
                        )
                      })
                    }
                    {

                      post?.points?.map((item) => {
                        return (
                          <>
                            <p className="fw-bold">
                              {item.title1}
                            </p>
                            <ul className="list-group">
                              {
                                item.subpoints?.map((subPoint) => {
                                  const stringArr = subPoint?.points?.split(':')
                                  return (
                                    <li style={{ listStyleType: "none" }}>
                                      <div style={{ display: "inline-block" }}>
                                        <i class="fa fa-check-circle" style={bullet}></i>
                                      </div>
                                      {
                                        stringArr == null ?
                                          null :
                                          stringArr.length < 2 ?
                                            <span style={listContent}>
                                              {/* {lastWord(post.points)} */}
                                              <span>{stringArr[0]}</span>
                                              {subPoint.description != null && subPoint.description != 'null' ?
                                                <p className="mt-3">
                                                  {subPoint.description}
                                                </p>
                                                :
                                                null
                                              }
                                            </span>
                                            :
                                            <span style={listContent}>
                                              <strong>{stringArr[0]}: </strong>
                                              {/* {lastWord(post.points)} */}
                                              <span>{stringArr[1]}</span>
                                              {subPoint.description != null && subPoint.description != 'null' ?
                                                <p className="mt-3">
                                                  {subPoint.description}
                                                </p>
                                                :
                                                null
                                              }
                                            </span>

                                      }
                                    </li>
                                  )
                                })
                              }
                            </ul>
                          </>
                        )
                      })

                    }

                  </>
                }
              />

              {
                subPosts?.map((subPost, index) => {
                  return (
                    <>
                      <FabricCard
                        title={subPost?.title}
                        rtl={index % 2 == 1 ? true : false}
                        ltr={index % 2 == 0 ? true : false}
                        image={subPost?.images}
                        html={
                          <>
                            <br />
                            <br />
                            {
                              subPost?.productdescriptions?.map((desc) => {
                                return (
                                  <>
                                    <p>
                                      {desc.description}
                                      {/* We are supplying premium quality yarn from Pakistan, India, China, Turkey, Egypt, Vietnam, and Indonesia. */}
                                    </p>

                                  </>
                                )
                              })
                            }

                            {

                              subPost?.points?.map((item) => {
                                return (
                                  <>
                                    <p className="fw-bold">
                                      {item.title1}
                                    </p>
                                    <ul className="list-group">
                                      {
                                        item.subpoints?.map((subPoint) => {
                                          const stringArr = subPoint?.points?.split(':')

                                          return (
                                            <li style={{ listStyleType: "none" }}>
                                              <div style={{ display: "inline-block" }}>
                                                <i class="fa fa-check-circle" style={bullet}></i>
                                              </div>
                                              {
                                                stringArr == null ?
                                                  null :
                                                  stringArr.length < 2 ?
                                                    <span style={listContent}>
                                                      {/* {lastWord(post.points)} */}
                                                      <span>{stringArr[0]}</span>
                                                      {subPoint.description != null && subPoint.description != 'null' ?
                                                        <p className="mt-3">
                                                          {subPoint.description}
                                                        </p>
                                                        :
                                                        null
                                                      }
                                                    </span>
                                                    :
                                                    <span style={listContent}>
                                                      <strong>{stringArr[0]}: </strong>
                                                      {/* {lastWord(post.points)} */}
                                                      <span>{stringArr[1]}</span>
                                                      {subPoint.description != null && subPoint.description != 'null' ?
                                                        <p className="mt-3">
                                                          {subPoint.description}
                                                        </p>
                                                        :
                                                        null
                                                      }
                                                    </span>

                                              }

                                            </li>
                                          )
                                        })
                                      }
                                    </ul>

                                  </>
                                )
                              })

                            }



                          </>
                        }
                      />


                    </>

                  )
                })
              }



            </div>
            <br />
            <div className="container-fluid" style={{ background: "#F4FCFF" }}>
              <div className="container-fluid">
                <div
                  className="container-fluid"
                  style={{ width: "100%", margin: "auto", padding: "50px 0" }}
                >
                  <Form
                    image="/media/Products/Home-Textile/home-textile-inquiry-bg.jpg"
                    apiType={'home-textile'}
                    tag="Your Enquiry"
                  />
                </div>
              </div>
            </div>
          </section>
        ))}
    </>
  );
}
