import React from 'react'
import Header from '../../Components/Header';
import NavigationHeader from '../../Components/NavigationHeader';
import ProForm from '../Products/ProForm';

export default function Yarn() {
  document.title = "Yarn Inquiry Form - Skytex";
  return (
    <section>
      <Header/>
      <NavigationHeader page="Yarn Inquiry Form" background="/media/OnlineInquiry/yarn-inquiry-bg.jpg"/>

      <div>
        <ProForm image="/media/OnlineInquiry/yarn-inquiry-form-bg.jpg" inquiryForm info="Yarn"
        tag="Send us your inquiry we will quote you best Price"/>
      </div>
    </section>
  )
}
