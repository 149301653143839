import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import NavigationHeader from "../../Components/NavigationHeader";
import Form from "../Contact/Form";
import FabricCard from "./FabricCard";
import QualityCard from "./QualityCard";
import TypeWriterEffect from "react-typewriter-effect";
import AxiosInstance from "../../Axios";
import { Helmet } from "react-helmet";

export default function Garments() {
  document.title = "Garments - Skytex";
  const short_title = {
    color: "#595959",
    fontSize: "19px",
    fontWeight: "bold",
    marginBottom: "10px",
    display: "block",
  };
  const bullet = {
    color: "#00AFF0",
    fontSize: "20px",
  };

  const listContent = {
    color: "#444444",
    fontSize: "22px",
    textAlign: "justify",
  };

  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(true);
  const [subPosts, setSubPosts] = useState([]);

  useEffect(() => {
    AxiosInstance.get(`api/product`)
      .then((res) => {
        console.log(res.data.successData.slider);
        setPosts(res.data.successData.data);
        //   setPosts(res.data);
      })
      .catch((error) => {
        setError(error.message);
        console.log(error.message);
      });
    AxiosInstance.get(`api/product-category/38`)
      .then((res) => {
        setSubPosts(res.data.successData.category);
      })
      .catch((error) => {
        //  console.log(error.message);
      });
  }, []);

  function lastWord(words) {
    var n = words.split(/[\s:]+/);
    return n[n.length - 1];
  }

  return (
    <>
      <Helmet>
        <title>About-Skytex</title>
      </Helmet>
      {posts?.filter((post) => post.id == 38)
        .map((post) => (
          <section>
            <Header />
            <NavigationHeader
              page={post.title}
              reqBtn
              background="/media/Products/Garments/garments-bg-1.jpg"
            />
            <br />
            <br />
            <div className="container garment-container garment-order">
              <div className="container garment-top-container text-center">
              <span style={short_title}>Our Products</span>
                <div style={{ display: "flex", justifyContent: `center`, alignItems: `center` }}>
                  <h1 style={{ fontSize: "42px", fontWeight: "bold", color: "#595959", borderBottom: "2px solid #00aff0", paddingBottom: "10px" }} className={` bordered_heading text-center`}>Garments</h1>
                </div>
                <br />
                {
                  post?.productdescriptions?.map((desc) => {
                    return (
                      <>
                        <p style={{textAlign: "center"}}>
                          {desc.description}
                          {/* We are supplying premium quality yarn from Pakistan, India, China, Turkey, Egypt, Vietnam, and Indonesia. */}
                        </p>
                        <br />
                      </>
                    )
                  })
                }
              </div>              

              {

                post?.points?.map((item) => {
                  return (
                    <>
                      <p className="fw-bold">
                        {item.title1}
                      </p>
                      <ul className="list-group">
                        {
                          item.subpoints?.map((subPoint) => {
                            const stringArr = subPoint?.points?.split(':')
                            return (
                              <li style={{ listStyleType: "none" }}>
                                <div style={{ display: "inline-block" }}>
                                  <i class="fa fa-check-circle" style={bullet}></i>
                                </div>
                                {
                                  stringArr == null ?
                                    null :
                                    stringArr.length < 2 ?
                                      <span style={listContent}>
                                        {/* {lastWord(post.points)} */}
                                        <span>{stringArr[0]}</span>
                                        {subPoint.description != null && subPoint.description != 'null' ?
                                          <p className="mt-3">
                                            {subPoint.description}
                                          </p>
                                          :
                                          null
                                        }
                                      </span>
                                      :
                                      <span style={listContent}>
                                        <strong>{stringArr[0]}: </strong>
                                        {/* {lastWord(post.points)} */}
                                        <span>{stringArr[1]}</span>
                                        {subPoint.description != null && subPoint.description != 'null' ?
                                          <p className="mt-3">
                                            {subPoint.description}
                                          </p>
                                          :
                                          null
                                        }
                                      </span>

                                }
                              </li>
                            )
                          })
                        }
                      </ul>

                    </>
                  )
                })

              }

              {
                subPosts?.map((subPost, index) => {
                  return (
                    <>
                      <FabricCard
                      id={index==1 ? 'abc' :'xyz'}
                        title={subPost.title}
                        rtl={index % 2 == 1 ? true : false}
                        ltr={index % 2 == 0 ? true : false}
                        image={subPost?.images}
                        html={
                          <>
                            <br />
                            <br />
                            {
                              subPost?.productdescriptions?.map((desc) => {
                                return (
                                  <>
                                    <p>
                                      {desc.description}
                                      {/* We are supplying premium quality yarn from Pakistan, India, China, Turkey, Egypt, Vietnam, and Indonesia. */}
                                    </p>
                                   
                                  </>
                                )
                              })
                            }

                            {
                              subPost?.points?.map((item) => {
                                return (
                                  <>
                                    <p className="fw-bold mt-4">
                                      {item.title1}
                                    </p>
                                    <ul className="two-columns-list">
                                      {
                                        item.subpoints?.map((subPoint) => {
                                          const stringArr = subPoint?.points?.split(':')

                                          return (
                                            <li>
                                              <div style={{ display: "inline-block" }}>
                                                <i class="fa fa-check-circle" style={bullet}></i>
                                              </div>
                                              {
                                                stringArr == null ?
                                                  null :
                                                  stringArr.length < 2 ?
                                                    <span style={listContent}>
                                                      {/* {lastWord(post.points)} */}
                                                      <span>{stringArr[0]}</span>
                                                      {subPoint.description != null && subPoint.description != 'null' ?
                                                        <p className="mt-3">
                                                          {subPoint.description}
                                                        </p>
                                                        :
                                                        null
                                                      }
                                                    </span>
                                                    :
                                                    <span style={listContent}>
                                                      <strong>{stringArr[0]}: </strong>
                                                      {/* {lastWord(post.points)} */}
                                                      <span>{stringArr[1]}</span>
                                                      {subPoint.description != null && subPoint.description != 'null' ?
                                                        <p className="mt-3">
                                                          {subPoint.description}
                                                        </p>
                                                        :
                                                        null
                                                      }
                                                    </span>

                                              }

                                            </li>
                                          )
                                        })
                                      }
                                    </ul>
                                  </>
                                )
                              })

                            }
                          </>
                        }
                      />

                    </>
                  )
                })
              }
            </div>

            <div className="container-fluid" style={{ background: "#F4FCFF" }}>
              <div className="container-fluid">
                <div
                  className="container-fluid"
                  style={{ width: "100%", margin: "auto", padding: "50px 0" }}
                >
                  <Form
                    image="/media/Products/Garments/inquiry-form-bg.jpg"
                    apiType={'garments'}
                    tag="Your Inquiry"
                  />
                </div>
              </div>
            </div>
          </section>
        ))}
    </>
  );
}
