import './App.css';
import Header from './Components/Header';
import Home from './Pages/Home/Home';
import About from './Pages/About/About';
import Services from './Pages/Services/Services';
// import 'animate.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import TextileFairs from './Pages/ConTextileFairs/TextileFairs';
import Blog from './Pages/Blog/Blog';
import Careers from './Pages/Careers/Careers';
import Yarn from './Pages/Products/Yarn';
import YarnForm from './Pages/InquiryForm/YarnForm';
import Contact from './Pages/Contact/Contact';
import FabricForm from './Pages/InquiryForm/FabricForm';
import GeneralInquiry from './Pages/InquiryForm/GeneralInquiry';
import Footer from './Components/Footer';
import Follow from './Components/FollowUs';
import YarnCategory from './Pages/Products/YarnCategory';
import Synthetic from './Pages/Products/Synthetic';
import Cellulose from './Pages/Products/Cellulose';
import HomeTextile from './Pages/Products/HomeTextile';
import Garments from './Pages/Products/Garments';
import MelangeYarn from './Pages/Products/MelangeYarn';
import BlogDetails from './Pages/Blog/BlogDetails';
import FollowUs from './Components/FollowUs';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useDispatch, useSelector } from 'react-redux';
import {setTicker,setLogo, setProducts, setCategories} from './Redux/Action'
import { useEffect } from 'react';
import AxiosInstance from './Axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Product from './Pages/Products/Product';

function App() {
  AOS.init();

  const dispatch = useDispatch();
  const ProductReducer=useSelector(state => state.ProductReducer)
  const CategoriesReducer=useSelector(state => state.CategoriesReducer)
  useEffect(() => {
    AxiosInstance.get(`api/logo`)
      .then((res) => {
        dispatch(setLogo(res.data.successData.logo));
      })
      .catch((error) => {
        // console.log(error.message);
      });

  AxiosInstance.get(`api/ticker`)
      .then((res) => {
        dispatch(setTicker(res.data.successData.data));
      })
      .catch((error) => {
        // console.log(error.message);
      });

    AxiosInstance.get(`api/product`)
      .then((res) => {
         dispatch(setProducts(res.data.successData.data));
      })
      .catch((error) => {
        // console.log(error.message);
      })
      
  }, []);

  
  
  return (
    <>
      <ToastContainer style={{zIndex: 100}} />

      {/* <Router> */}
        <Routes>
          <Route path="/" element={<Home /> } />
          <Route path="/about-us" element={<About />} />
          <Route path="/our-services" element={<Services />} />
          <Route path="/connection-with-textile-fairs" element={<TextileFairs />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:blogId" element={<BlogDetails />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/yarn" element={<Yarn />} />
          <Route path="/yarn-category/:product_id/:product_title/:category_id" element={<YarnCategory />} />
          <Route path="/cellulose-blended-yarn" element={<Cellulose />} />
          <Route path="/synthetic-blended-yarn" element={<Synthetic />} />
          <Route path="/melange-yarn" element={<MelangeYarn />} />
          <Route path="/product/:id/:title" element={<Product />} />
          <Route path="/home-textile" element={<HomeTextile />} />
          <Route path="/garments" element={<Garments />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/yarn-inquiry-form" element={<YarnForm />} />
          <Route path="/fabric-inquiry-form" element={<FabricForm />} />
          <Route path="/general-inquiry" element={<GeneralInquiry />} />
        </Routes>
      {/* </Router> */}
      <Follow/>
        <Footer />
      </>
  );
}

export default App;