import React, { useEffect, useState } from "react";
import AxiosInstance from "../../Axios";
import styles from "./Home.module.css";
import "../../Sliders.css";
import "./slick.css";
import Slider from "react-slick/lib/slider";

export default function Certifications() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [posts, setPosts] = useState([]);

  useEffect(() => {
    AxiosInstance.get(`api/home-page`)
      .then((res) => {
        setPosts(res.data.successData.certification);
      })
      .catch((error) => {
        // console.log(error.message);
      });
  }, []);

  return (
    <>
      <section className={`certifications`}>
        <div className={`container`}>
          <div className={`row ${styles.Certifications_row}`}>
            <div
              className={`col-12 col-md-3 col-lg-3 pe-0 ${styles.Certifications_head}`} data-aos="zoom-in-right" data-aos-duration="4000" data-aos-once="true" style={{ display: "flex", alignItems: "center" }}
            >
              <div>
                <h1
                  className="sub-heading"
                >
                  OUR SUPPLIERS
                </h1>
                <h1 className="bordered_heading mb-0">
                  Certification
                </h1>
              </div>
            </div>
            <div className="col-12 col-md-9 col-lg-9" data-aos="zoom-in" data-aos-duration="4000" data-aos-delay="1000" data-aos-once="true">
              <Slider {...settings} className="cert">
                {posts
                  .sort((a, b) => a["id"] - b["id"])
                  .map((post) => (
                    <img
                      key={post.id}
                      src={post.image}
                      className="p-1 get"
                      style={{ width: " 70%" }}
                    />
                  ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
