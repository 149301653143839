import React, { useEffect, useState } from "react";
import ProductsCard from "./ProductsCard";
import styles from "./Home.module.css";
import AxiosInstance from "../../Axios";
import { Link } from "react-router-dom";
import "./slick.css";
import Slider from "react-slick/lib/slider";
import { useSelector } from "react-redux";

export default function OurProducts() {
  const settings = {
    dots: false,
    // infinite: true,
    // speed: 500,
    slidesToShow: 4,
    // slidesToScroll: 0,
    // autoplay: true,
    // autoplaySpeed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          // slidesToScroll: 2,
          initialSlide: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    document
      .getElementById("OurProductsSlider")
      .parentNode.querySelectorAll(".slick-slide")
      // .forEach((el) => {
      //   el.style.setProperty("height", "65vh", "important");
      // });
  });

  const [posts, setPosts] = useState([]);
  const ProductReducer = useSelector(state=>state.ProductReducer)

  useEffect(() => {
    AxiosInstance.get(`api/home-page`)
      .then((res) => {
        setPosts(res.data.successData.homeproduct);
      })
      .catch((error) => {
        // console.log(error.message);
      });
  }, []);

  return (
    // <section >
    <section className="products-section"
      style={{
        background: `linear-gradient(rgba(21, 21, 21, 0.8), rgba(21, 21, 21, 0.8)),url("media/our-product-bg.jpg")`,
      }}
    >
      <p
        className={`text-center text-white fw-bold ${styles.Our_products}`}
        data-aos="zoom-in"
        data-aos-duration="4000"
        data-aos-once="true"
      >
        Our Products
      </p>

      <div
        className="container-fluid mt-4"
        data-aos="zoom-in"
        data-aos-duration="4000"
        // data-aos-once="true"
        id="OurProductsSlider"
      >


<Slider {...settings} className="ourproduct ">
          {ProductReducer?.map((post) => {

            return (

              <div
                key={post.id}
                className="product"
                style={{ height: "", width: "10px" }}
              >
                {" "}
                {/*col-md-4*/}
                <div className={`${styles.OurProduct_main} rounded `}>
                  <Link
                    to={{
                      pathname: "/product",
                      // search: arrayStr.length > 1 ? `${arrayStr[0]}-${arrayStr[1]}` : `${arrayStr[0]}`
                    }}
                    state={{ product: post, category: null }}
                  >
                    <div className={`${styles.ProductCar} rounded center`}>
                      <img src={post.home_image} width="100%" alt="" />
                      <h4
                        className="view-more px-3 text-white py-2"
                      >
                        <span
                          to={``}
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          {" "}
                          View More{" "}
                        </span>
                      </h4>
                    </div>
                    <div className={`${styles.ProductText_body} center`}>
                      <div className={`${styles.ProductText}`}>
                        <span
                          to={``}
                          style={{ textDecoration: "none", color: "#000" }}
                        >
                          {post.title}{" "}
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            )
          })}
        </Slider>
      </div>
    </section>
  );
}





