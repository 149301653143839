import React, { useEffect, useRef, useState } from "react";
import Header from "../../Components/Header";
import styles from "./Home.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import WhoAreWe from "./WhoAreWe";
import WhyChooseUs from "./WhyChooseUs";
import NumbersCard from "./NumbersCard";
import OurProducts from "./OurProducts";
import Services from "./Services";
import ServedAreas from "./ServedAreas";
import Certifications from "./Certifications";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HomeCarousel from "./HomeCarousel";
import Offices from "./Offices";
import useOnScreen from "../../hooks/useOnScreen";

export default function Home() {
  const myRef = useRef(null);
  console.log({ myRef });
  const isVisible = useOnScreen(myRef);
  const [refreshCounter, setRefreshCounter] = useState(false);
  document.title='Skytex - Your Textile Connection'
  useEffect(() => {
    console.log({ isVisible });
    if (isVisible) {
      setRefreshCounter(true);
    } else {
      setRefreshCounter(false);
    }
  }, [isVisible]);
  return (
    <>
      <Header />
      <HomeCarousel />
      <WhoAreWe />
      <WhyChooseUs />
      {/* <br /><br /> */}
      {/* <div className="container" > */}
      <div className={`${styles.NumbersSection_container} container`}>
        {/* <div className={`${styles.NumbersSection} contaier`}> */}

        <div className="row" ref={myRef}>
          {(
            <>
              <NumbersCard
                title="Offices Around World"
                counter="4"
                image="/media/Home/offices-around-world.png"
              />
              <NumbersCard
                title="Exporting Countries"
                counter="15"
                showPlus
                image="/media/Home/exporting-countries.png"
              />
              <NumbersCard
                title="Suppliers Networks"
                counter="350"
                showPlus
                image="/media/Home/suppliers-network.png"
              />
              <NumbersCard
                title="Clients"
                counter="100"
                showPlus
                image="/media/Home/clients.png"
              />
            </>
          )}
        </div>

      </div>
      <OurProducts />
      <br />
      <Services />
      <ServedAreas />
      <Offices />
      <Certifications />
    </>
  );
}
