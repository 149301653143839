import React, { useEffect, useState } from 'react';
import AxiosInstance from '../../Axios';
import ServicesCard from './ServicesCard'
import styles from './Home.module.css'

export default function Services() {


  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(true)

  useEffect(() => {
    AxiosInstance.get(`api/home-page`)
      .then((res) => {
        //   console.log(res.data.successData.WhereWeAre);
        setPosts(res.data.successData.home_Services);
        //   setPosts(res.data);
      })
      .catch((error) => {
        setError(error.message);
        console.log(error.message);
      });
  }, []);


  const service = posts.length
    ? posts
      .sort((a, b) => a["id"] - b["id"])
      .map((post) => (
        <div key={post.id} className={`col-12 col-lg-4 col-md-6 mb-4`} data-aos="zoom-in" data-aos-duration="4000" data-aos-once="true">
          <div className={`${styles.ServiceCard}`} >
            {/* // <div className={`col-md-3 mb-4 ${styles.ServiceCard}`} data-aos="zoom-in" data-aos-duration="4000"> */}
            <div className={styles.serviceOverlay}>
              <div className='center'>
                <img src={post.image} width={100} />
              </div>
              <div><h3 className='text-center mt-3 fw-bold' style={{ color: "#595959" }}>{post.title}</h3></div>
            </div>
          </div>
        </div>
      )) : null;

  return (
    <section>
      <div className={`container ${styles.servicesCnt} services`}>
        <h2 className={`sub-heading`}>Work With Us</h2>
        <div className={`row ${styles.weAre_offering}`}>
          <div className="col-md-8">
            <h2 className='bordered_heading' data-aos="zoom-in" data-aos-duration="4000" data-aos-once="true">We are Offering Spectrum Services</h2>
          </div>
          <div className="col-md-4">

            <div className='rs-btn'>
              <a class="readon rs_button" href="/our-services">
                <span class="btn_text">All Services</span>
              </a>
            </div>
          </div>
        </div>
        <br /><br />
        <div className="row">
          {service}
        </div>
      </div>
    </section>
  )
}
