import React from 'react'
import Header from '../../Components/Header'
import NavigationHeader from '../../Components/NavigationHeader'
import ProForm from '../Products/ProForm';

export default function FabricForm() {
  document.title = "Fabric Inquiry Form - Skytex";

  return (
    <section>
      <Header/>
      <NavigationHeader page="Fabric Inquiry Form" background="/media/OnlineInquiry/fabric-inquiry-bg.jpg"/>
      
      <div>
        <ProForm image="/media/OnlineInquiry/fabric-inquiry-form-bg.jpg" inquiryForm info="Fabric" 
        tag="Send us your inquiry we will quote you best Price"/>
      </div>
    </section>
  )
}
