import React, { useEffect, useState } from 'react'
import AxiosInstance from '../../Axios';
import styles from './About.module.css'

export default function Ceo() {

  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(true)


  useEffect(() => {
    AxiosInstance.get(`api/about-page`)
      .then((res) => {
        setPosts(res.data.successData.ceoMessage);
      })
      .catch((error) => {
        setError(error.message);
        console.log(error.message);
      });
  }, []);

  return (
    <>
      {posts
        .filter((post) => post.id == 131)
        .map((post) => (
          <div id="ceo-message" key={post.id} className="container">
            <div key={post.id} className="row">
              <div className={`col-md-6`} style={{ display: "flex", alignItems: "center" }}>
                <div>
                  <div className='ceo-image' >
                    <img
                      src={post.image}
                      className={`${styles.flex_countries_img} `}
                      alt="Pakistan"
                    />
                  </div>
                  <div className={styles.aboutceo}>
                    <h3 style={{ fontWeight: "bold", fontSize: "25px", color: "#fff" }}>SHUJA UD DIN</h3>
                    <h4 style={{ fontWeight: "bold", fontSize: "18px", letterSpacing: "2.5", color: "#fff" }}>CEO & Founder</h4>
                  </div>
                </div>
              </div>
              {/* <div>Ceo */}
              {/* <div className={`col-md-6`} id="ceo-message">
    <div >Ceo */}
              <div className={`col-md-6`} style={{ display: "flex", alignItems: "center" }}>
                <div>
                  <div key={post.id}>
                    <h2 className={` bordered_heading`}>CEO Message</h2>
                    <p className={`${styles.short_title} mb-3`} style={{ fontSize: "22px", fontStyle: "italic", letterSpacing: "1.4px", color:"#444" }}>
                      {post.title}
                    </p>
                    <p className='text-justify'>
                      {post.description}
                    </p>
                  </div>
                </div>
              </div>
              {/* </div>
    </div> */}
            </div>
          </div>
        ))}
    </>
  )
}

// export default Ceo