import React from 'react'
import Header from '../../Components/Header'
import NavigationHeader from '../../Components/NavigationHeader'
import Form from '../Contact/Form'

export default function GeneralInquiry() {
  document.title = "General Inquiry Form - Skytex";

  return (
    <section>
      <Header/>
      <NavigationHeader page="General Inquiry" background="/media/OnlineInquiry/general-inquiry-bg.jpg"/>
      
      <div>
        <Form image="/media/OnlineInquiry/general-Inquiry-Form-Image.jpg" 
        info={'general-inquiry'}
        tag="General Inquiry"/>
      </div>
    </section>
  )
}
