import React, { useEffect, useState } from "react";
import AxiosInstance from "../../Axios";
import styles from "./Home.module.css";

export default function WhoAreWe() {
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(true);

  useEffect(() => {
    AxiosInstance.get(`api/home-page`)
      .then((res) => {
        console.log(res.data.successData.slider);
        setPosts(res.data.successData.home);
        //   setPosts(res.data);
      })
      .catch((error) => {
        setError(error.message);
        console.log(error.message);
      });
  }, []);

  return (
    <>
      {posts
        .filter((post) => post.id == 1)
        .map((post) => (
          <section key={post.id} className={`${styles.Years_of_experience}`}>
            <div className="container who-we-are">
              <div className="row">
                <div
                  className="col-md-6"
                  data-aos="zoom-in"
                  data-aos-duration="4000"
                  data-aos-once="true" style={{ display: "flex", alignItems: "center" }}
                >
                  <img src={post.image} alt="" />
                  {/* <div className={`${styles.who_we} row pt-2 `}>                    
                      <div className="col-md-3 col-3">
                        <i
                          className="fa fa fa-globe mt-3"
                          style={{ float: "right", fontSize: "50px" }}
                        ></i>
                      </div>
                      <div className="col-md-9 col-9">
                        <h1>30+</h1>
                        <p className={`${styles.WhoAreWe_text}`}>
                          Years of experience
                        </p>
                      </div>
                    </div> */}
                </div>
                <div
                  className="col-md-6 who-we-are-text"
                  data-aos="zoom-in"
                  data-aos-duration="4000"
                  data-aos-delay="500" data-aos-once="true" style={{ display: "flex", alignItems: "center" }}
                >
                  <div>
                  <h3
                    className={`sub-heading m-0 p-0 mb-2`}
                    style={{ fontSize: "20px" }}
                  >
                    SKYTEX
                  </h3>
                  <h1 className={`${styles.main_title} bordered_heading`}>
                    {post.title}
                  </h1>
                  
                  <p className={styles.about_our_p}>{post.description}</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ))}
    </>
  );
}
