import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import "./Blog.module.css"
import styles from "./Blog.module.css"
import AxiosInstance from '../../Axios';
import Header from '../../Components/Header';
import NavigationHeader from '../../Components/NavigationHeader';
import { Helmet } from 'react-helmet';
import { HashLink } from 'react-router-hash-link';

const BlogDetails = (props) => {
    const {blogId} = useParams()

    

    
    const [posts, setPosts] = useState([]);

    // const TITLE = 'My Page Title'

    useEffect(() => {
      AxiosInstance.get(`api/blog`)
        .then((res) => {
          setPosts(res.data.successData.blogs);
        })
        .catch((error) => {
          // console.log(error.message);
        });
    }, []);
  
    const blog = posts.length
      ? posts
      .filter((post) => post.title == blogId)
          .map((post) => (
          <div key={post.title}>
             <Helmet>
        <title>{post.title}- Skytex</title>
    </Helmet>
            <img   src={post.image}
            className="card-img-top"
            style={{ cursor: "pointer" }}
            alt={post.title}
          />

          <div>
          <p style={{ lineHeight: "1.3" }} className="card-text mt-4">
                  {post.description}
                </p>
          </div>
          </div>
          )) :null 

    return (
        <>
        
        {/* <div>    */}
          <Header />
            <NavigationHeader
              // page={post.title}
              page="Skytex / Blog / Category One "
              reqBtn
              background="/media/Products/Fabric/fabric-bg.jpg"
            />
        <div className={`container`}>
          <div className={`${styles.main_detailed_blog} row`}>
            <div className='col-12 col-lg-3 col-md-3'> 
              <div className={`${styles.blog_side1}  `}>
                <h1  className={`${styles.blog_t} bordered_heading`}>Categories</h1>
                <ul  className={`${styles.blog_ul} `}>
                  <li className={`${styles.blog_li}`}><Link to="/blog" className={`${styles.blog_link} `}>&gt; Category One</Link></li>
                </ul>
              </div>

            <div className={`${styles.blog_side}  `} >
              <h1  className={`${styles.blog_s} bordered_heading  pb-0`}>Our Products </h1>
              <ul  className={`${styles.blog_ul}  `}>
                <li className={`${styles.blog_li} `}><Link to="/yarn" className={`${styles.blog_link} `}> Yarn</Link></li>
                <li className={`${styles.blog_li} `}><Link to="/fabric" className={`${styles.blog_link} `}> Fabric</Link></li>
                <li className={`${styles.blog_li} `}><Link to="/home-textile" className={`${styles.blog_link} `}> Home Textile</Link></li>
                <li className={`${styles.blog_li} `}><Link to="/garments" className={`${styles.blog_link} `}> Garments</Link></li>
              </ul>
            </div>

            <div className={`${styles.blog_side}  `}>
              <h1 className='bordered_heading  pb-0'>Our Services</h1>
            {/* <ul className='blog-ul'  className={`${styles.blog_ul} dropdown-item text-white `}> */}
              <ul  className={`${styles.blog_ul}  `}>
                <li className={`${styles.blog_li} `}><HashLink to="/our-services/#admirable sourcing" className={`${styles.blog_link} `}>Admirable Sourcing</HashLink></li>
                <li className={`${styles.blog_li} `}><HashLink to="/our-services/#quality control" className={`${styles.blog_link} `}>Quality Control policy</HashLink></li>
                <li className={`${styles.blog_li} `}><HashLink to="/our-services/#product" className={`${styles.blog_link} `}>Product Development</HashLink></li>
                <li className={`${styles.blog_li} `}><HashLink to="/our-services/#sampling" className={`${styles.blog_link} `}>Sampling</HashLink></li>
                <li className={`${styles.blog_li} `}><HashLink to="/our-services/#customer meeting" className={`${styles.blog_link} `}>Customer Meeting Coordination</HashLink></li>
                <li className={`${styles.blog_li} `}><HashLink to="/our-services/#after sale" className={`${styles.blog_link} `}>After Sale Servicess</HashLink></li>
              </ul>
            </div>
            </div>

            <div className='col-12 col-lg-9 col-md-8'> 
            {blog}
              <div className='row'>
                <div className='col-12'>
              <h1  className='bordered_heading mt-4'>Who We Are?</h1>
                </div>
                <div className='col-12'>
              <p> SKYTEX is the most reliable Buying house, which has competency in all type of Yarn, Fabrics, Bed linen, towels and garments. We are well-known in the field of Sourcing & Inspection our team accepts new challenges of textile industry respond with innovative solutions .We commit to maintain top-notch quality of our products. Our approach is to collaborate directly with customers at a design and aesthetic level to develop a focused understanding of their goals and requirements. We ensure that goods are delivered on time and in full to the quality standards and price points consistent with the market strategy of each of our customers.
              </p>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                <img   src="/media/Products/Yarn/yarn1.jpg"
                          className="card-img-top"
                          style={{ cursor: "pointer", paddingBottom: "30px" }}
                          alt="who we are"
                        />
                </div>
                <div className='col-12'>
              <h1  className='bordered_heading mt-4'>Why Choose Us?</h1>
              <p>
                SKYTEX have good connections with leading textile mills and manufacturing resources. We are providing quality goods, competitive prices, and timely delivery from reliable suppliers we have suppliers that can facilitate purchase of our customers, our skilled team inspects their factory on daily bases and we do comprehensive testing to ensure that customer’s requirement must be fulfilled genuinely. We are 100% confident that no other textile agent can provide quality that we are providing.
                Our quality and work processes prove that you can rely on us.</p>
                <br/>
                <br/>
                <br/>
                <hr/>
                {blogId =="Skytex farhan Blog Two" ?( 
                <Link  className={`${styles.blog_link} `}  to={`/blog/Skytex Blog One`} >
                  <p className='text-end'>Next</p>
                  <p className='text-end'>&rarr;</p>
                </Link>):(
                <Link  className={`${styles.blog_link} `}  to={`/blog/Skytex farhan Blog Two`} >
                <p className='text-end'>Next</p>
                <p className='text-end'>&rarr;</p>
              </Link>)
                }
                <hr/>
                <br/>
                <br/>
                </div>
              </div>
            </div>
          </div>
        </div>     
        </>
    );
};

export default BlogDetails;