import React, { useEffect, useState } from "react";
import AxiosInstance from "../../Axios";
import Header from "../../Components/Header";
import NavigationHeader from "../../Components/NavigationHeader";

export default function Careers() {
  const main_title = {
    fontSize: "46px",
    fontWeight: "bold",
    color: "#595959",
  };

  document.title = "Careers - Skytex";

  const [posts, setPosts] = useState([]);

  useEffect(() => {
    AxiosInstance.get(`api/career-page`)
      .then((res) => {
        setPosts(res.data.successData.career);
      })
      .catch((error) => {
        //  console.log(error.message);
      });
  }, []);

  const careers = posts.length
    ? posts
        .sort((a, b) => a["id"] - b["id"])
        .map((post) => (
          <div className="row career section-padding-80">
            <div className="col-md-6" data-aos="fade-in" data-aos-duration="4000" data-aos-once="true" style={{ display: "flex", justifyContent: `center`, alignItems: `center` }}>
              <img
                src={post.image}
                width={""}
                className=""
                style={{ boxShadow: "0px 0px 16px 0px rgb(0 0 0 / 42%)" }}
                alt="hiring"
              />
            </div>
            <div className={`col-md-6 career-text`} data-aos="fade-in" data-aos-duration="3000" data-aos-delay="1000" data-aos-once="true">
              <div>
                <h2 className={`bordered_heading`}>
                  {post.title}
                </h2>
                <p
                  className={``}
                  style={{ fontSize: "", letterSpacing: "" }}
                >
                  {post.description}
                </p>
                <p className="text-justify">
                   Visit our LinkedIn Profile for new job posts.
                  <br /> Apply now:
                  <br />{" "}
                  <a
                    className="text-decoration-none text-black"
                    href="mailto:hr@skytex.com.pk"
                  >
                    <strong>hr@skytex.com.pk</strong>
                  </a>
                </p>
              </div>
            </div>
          </div>
        ))
    : null;

  return (
    <section>
      <Header />
      <NavigationHeader
        page="Careers"
        background="/media/Career/career-bg.jpg"
      />

      <div className="container">{careers}</div>
    </section>
  );
}
