import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AxiosInstance from "../../Axios";
import Header from "../../Components/Header";
import NavigationHeader from "../../Components/NavigationHeader";
import BlogCard from "./BlogCard";
import styles from "./Blog.module.css";

export default function Blog() {
  document.title = "Blogs - Skytex";
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    AxiosInstance.get(`api/blog`)
      .then((res) => {
        setPosts(res.data.successData.blogs);
      })
      .catch((error) => {
        // console.log(error.message);
      });
  }, []);

  const blog = posts.length
    ? posts
        .sort((a, b) => a["id"] - b["id"])
        .map((post) => (
          <div key={post.id} className="col-md-6">
            <div
              className="card border-0"              
            >
              <img
                src={post.image}
                className="card-img-top"
                style={{ cursor: "pointer" }}
                alt={post.title}
              />
              <div className="card-body p-5">
                <Link className="text-decoration-none" to={`${post.title}`}>
                  <h5 className={`${styles.short_title} card-title `}>
                    {post.title}
                  </h5>
                </Link>
                <i
                  className="fa fa-calendar-check-o"
                  style={{ color: "#00AFF0" }}
                ></i>
                <span className="p-1">April 27, 2022</span>
                <i
                  className="fa fa-user-o p-2"
                  style={{ color: "#00AFF0" }}
                ></i>
                <span className="p-1">Michael</span>
                <i className="fa fa-book p-2" style={{ color: "#00AFF0" }}></i>
                <span>Category One</span>
                <p className="card-text mt-4">
                  {post.description}
                </p>
                <Link
                  to={`/blog/${post.title}`}
                  className={`btn btn-white bg-white text-black p-0 ${styles.main_title}`}
                  style={{ fontSize: "22px", fontWeight: "bold" }}
                >
                  Continue Reading &rarr;
                </Link>
              </div>
            </div>
          </div>
        ))
    : null;

  return (
    <section>
      <Header />
      <NavigationHeader
        page="Blog"
        background="/media/Blog/blog-bg.jpg"
      />

      <div className="container blog section-padding-80">
        <div className="row">{blog}</div>
      </div>
    </section>
  );
}
