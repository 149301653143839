import React, { useEffect, useRef, useState } from "react";
import styles from "./Home.module.css";
import TypeWriterEffect from "react-typewriter-effect";
import Typewriter from "typewriter-effect";
import AxiosInstance from "../../Axios";
import useOnScreen from "../../hooks/useOnScreen";
import SmoothText from "./SmoothText";

export default function WhyChooseUs() {
  const [posts, setPosts] = useState([]);

  const [wi, setWi] = useState("");
  const wid = "50%";
  useEffect(() => {
    AxiosInstance.get(`api/home-page`)
      .then((res) => {
        setPosts(res.data.successData.home);
      })
      .catch((error) => {
        //  console.log(error.message);
      });
  }, []);

  useEffect(() => {
    if (isVisible) {
      setTimeout(() => {
        document.getElementById("progress-bar1").style.width = "100%";
        document.getElementById("progress-bar2").style.width = "86%";
        document.getElementById("progress-bar3").style.width = "89%";
        document.getElementById("progress-bar4").style.width = "95%";
        document.getElementById("progress-bar5").style.width = "92%";
      }, 1000);
    }
  });
  const barRef = useRef(null);
  const isVisible = useOnScreen(barRef);
  const [refreshCounter, setRefreshCounter] = useState(false);
  useEffect(() => {
    console.log({ isVisible });
    if (isVisible) {
      setRefreshCounter(true);
    } else {
      setRefreshCounter(false);
    }
  }, [isVisible]);

  return (
    <div ref={barRef}>
      {posts
        .filter((post) => post.id == 2)
        .map((post) => (
          <section key={post.id}>
            <div className="container-fluid">
              <div className="row">
                <div
                  className={`${styles.WhyChooseUs} col-lg-7 asas col-md-12`}
                  style={{
                    position: "relative",
                    background: `linear-gradient(rgba(21, 21, 21, 0.7), rgba(21, 21, 21, 0.7)), url(${post.image}) no-repeat`,
                    backgroundSize: `cover`,
                    height: "63vh + auto",
                  }}
                >
                  <div data-aos="zoom-in" data-aos-duration="4000" data-aos-once="true">
                    <h1
                      className={styles.WhyChooseUsFormat}
                      style={{ fontSize: "50px", fontWeight: "700", }}
                    >
                      Why Choose Us?
                    </h1>
                    <h3
                      className={styles.WhyChooseUsFormat}
                      style={{
                        fontSize: "34px",
                        lineHeight: "40px",
                        letterSpacing: "0.4px",
                        fontWeight: "700",
                      }}
                    >
                      {post.title.slice(0, 28)}{" "}
                      {/* We are dedicated to provide{" "} */}
                      <span
                        style={{ display: "inline-block", color: "#00AFF0", marginTop: "-60px" }}
                        className="wrapper"
                      >
                        <SmoothText />
                      </span>{" "}
                      {post.title.slice(55, 97)}
                    </h3>
                    <p className={styles.WhyChooseUsFormat}
                      style={{ fontWeight: "500", textAlign: "justify" }}>
                      {post.description}
                    </p>
                  </div>
                </div>
                {/* <div className="col-md-6 bg-light p-5" style={{ height: "63vh",  }}> */}
                <div className="col-lg-5 col-md-12 bg-light p-5 ">
                  <div data-aos="zoom-in" data-aos-duration="4000" data-aos-delay="500" data-aos-once="true">
                    <h2
                      className={`bordered_heading m-0 p-0 mb-2`}                      
                    >
                      Our Core Values
                    </h2>
                    {(
                      <div className="row">
                        <div className="col-md-12 mt-3 pt-2">
                          <span
                            style={{ fontSize: "22px" }}
                            className="animate__animated animate__bounce"
                          >
                            Quality
                          </span>

                          <div className="progress" style={{ height: "31px" }}>
                            <div
                              id="progress-bar1"
                              className="progress-bar"
                              role="progressbar"
                              style={{
                                // width: wid,
                                width: "30%",
                                background: "#00AFF0",
                                textAlign: "right",
                                fontSize: "22px",
                                transition: "width 5s",
                                // height:"100%",
                              }}
                              aria-valuenow={100}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            >
                              100%&nbsp;&nbsp;&nbsp;&nbsp;
                            </div>
                          </div>
                          {/* </AnimatedOnScroll> */}
                        </div>
                        <div className="col-md-12 mt-2 pt-2">
                          <span
                            style={{ fontSize: "22px" }}
                            className="animate__animated animate__bounce"
                          >
                            Innovation
                          </span>
                          <div className="progress" style={{ height: "31px" }}>
                            <div
                              id="progress-bar2"
                              className="progress-bar"
                              role="progressbar"
                              style={{
                                width: "30%",
                                background: "#00AFF0",
                                textAlign: "right",
                                fontSize: "22px",
                                transition: "width 5s",
                                // transition: "1s width ease",
                                // transitionDelay: "0.5s",
                              }}
                              aria-valuenow={86}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            >
                              86%&nbsp;&nbsp;&nbsp;&nbsp;
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 mt-2 pt-2">
                          <span
                            style={{ fontSize: "22px" }}
                            className="animate__animated animate__bounce"
                          >
                            Reliance
                          </span>
                          <div className="progress" style={{ height: "31px" }}>
                            <div
                              id="progress-bar3"
                              className="progress-bar"
                              role="progressbar"
                              style={{
                                width: "20%",
                                background: "#00AFF0",
                                textAlign: "right",
                                fontSize: "22px",
                                transition: "width 5s",
                              }}
                              aria-valuenow={89}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            >
                              89%&nbsp;&nbsp;&nbsp;&nbsp;
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 mt-2 pt-2">
                          <span
                            style={{ fontSize: "22px" }}
                            className="animate__animated animate__bounce"
                          >
                            Performance
                          </span>
                          <div className="progress" style={{ height: "31px" }}>
                            <div
                              id="progress-bar4"
                              className="progress-bar"
                              role="progressbar"
                              style={{
                                width: "20%",
                                background: "#00AFF0",
                                textAlign: "right",
                                fontSize: "22px",
                                transition: "width 5s",
                              }}
                              aria-valuenow={100}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            >
                              95%&nbsp;&nbsp;&nbsp;&nbsp;
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 mt-2 pt-2">
                          <span
                            style={{ fontSize: "22px" }}
                            className="animate__animated animate__bounce"
                          >
                            Competence
                          </span>
                          <div className="progress" style={{ height: "31px" }}>
                            <div
                              id="progress-bar5"
                              className="progress-bar"
                              role="progressbar"
                              style={{
                                width: "20%",
                                background: "#00AFF0",
                                textAlign: "right",
                                fontSize: "22px",
                                transition: "width 5s",
                              }}
                              aria-valuenow={92}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            >
                              92%&nbsp;&nbsp;&nbsp;&nbsp;
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        ))}
    </div>
  );
}
