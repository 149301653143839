import React from "react";

function SmoothText() {
  return (
    <>
      <ul class="dynamic-txts">
        <li>
          <span>Quality</span>
        </li>
        <li>
          <span>Trust</span>
        </li>
        <li>
          <span>Personalization</span>
        </li>
      </ul>
    </>
  );
}

export default SmoothText;
