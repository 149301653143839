import React, { useEffect, useState } from "react";
import CountUp from "react-countup";

export default function NumbersCard(props) {
  const [counter, setCounter] = useState(0);

  return (
    <div className="col-md-6 col-lg-3 col-sm-6">
      <div className="counters" style={{display: "flex", alignItems:"center"}}>
        <div className="counter-img">
          <img
            src={`${props.image}`}
            style={{ width: "55px" }}
          />
        </div>
        <div className="counter-info ps-2">
          <p
            style={{
              fontSize: "40px",              
              paddingBottom: "0",
              marginBottom: "0",
            }}
          >
            <CountUp end={props.counter} duration={5} />
            {props.showPlus && " + "}
          </p>
          <p style={{ fontSize: "21px", paddingTop: "0" }}>
            {props.title}
          </p>
        </div>
      </div>
    </div>
  );
}
