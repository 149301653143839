import React from "react";
import { VectorMap } from "react-jvectormap";
const mapData = {
  FR: 250,
  BR: 76,
  CL: 152,
  BD: 50,
  ES: 724,
  PT: 620,
  DE: 276,
  IT: 380,
  GB: 826,
  MX: 480,
  CN: 156,
  DE: 276,
  GT: 320,
  BE: 56,
  NL: 528,
  CZ: 203,
  PL: 616,
  BG: 100,
  TW: 158,
  MY: 458,
  TH: 764,
};
const handleClick = (e, countryCode) => {
  console.log(countryCode);
};
const Map = () => {
  return (
    <div>
      <div className="border" data-aos="zoom-in" data-aos-duration="4000" data-aos-delay="500" data-aos-once="true">
        <VectorMap
          map={"world_mill"}
          backgroundColor="transparent"
          zoomOnScroll={false}
          containerStyle={{
            width: "100%",            
            display: "block",
            
          }}
          onRegionClick={handleClick}
          containerClassName="map"
          regionStyle={{
            initial: {
              fill: "#e4e4e4",
              "fill-opacity": 0.9,
              stroke: "none",
              "stroke-width": 0,
              "stroke-opacity": 0
            },
            hover: {
              "fill-opacity": 0.8,
              cursor: "pointer"
            },
            selected: {
              fill: "#2938bc"
            },
            selectedHover: {}
          }}
          regionsSelectable={true}
          series={{
            regions: [
              {
                values: mapData,
                scale: ["#00AFF0",],
                normalizeFunction: "polynomial"
              }
            ]
          }}
        />
      </div>
    </div>
  );
};
export default Map;