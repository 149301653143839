import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import NavigationHeader from "../../Components/NavigationHeader";
import ProForm from "./ProForm";
import AxiosInstance from "../../Axios";
import FabricCard from "./FabricCard";
import { parseTwoDigitYear } from "moment";
import 'react-slideshow-image/dist/styles.css'
import { Fade } from 'react-slideshow-image';

export default function Yarn() {
  document.title = "Yarn - Skytex";
  const [posts, setPosts] = useState([]);
  const [subPosts, setSubPosts] = useState([]);
  const [belowPoints, setBelowPoints] = useState([]);

  useEffect(() => {
    AxiosInstance.get(`api/product`)
      .then((res) => {
        setPosts(res.data.successData.data);
      })
      .catch((error) => {
        //  console.log(error.message);
      });
    AxiosInstance.get(`api/product-category/39`)
      .then((res) => {
        setSubPosts(res.data.successData.category);
      })
      .catch((error) => {
        //  console.log(error.message);
      });
    AxiosInstance.get(`api/yarn-points`)
      .then((res) => {
        setBelowPoints(res.data.successData.data[0]);
      })
      .catch((error) => {
        //  console.log(error.message);
      });
  }, []);

  const short_title = {
    color: "#595959",
    fontSize: "19px",
    fontWeight: "bold",
    marginBottom: "10px",
    display: "block",
  };

  const main_title = {
    fontSize: "46px",
    fontWeight: "bold",
    color: "#595959",
  };

  const listContent = {
    color: "#444444",
    fontSize: "22px",
    textAlign: "justify",
  };

  const bullet = {
    color: "#00AFF0",
    fontSize: "20px",
  };

  return (
    <>
      <Header />

      {posts?.filter((post) => post.id == 39)
        .map((post) => (
          <section>
            <NavigationHeader
              page={post.title}
              reqBtn
              background={"/media/Products/Yarn/yarn-product-bg.jpg"}
            />
            <br />
            <br />
            <div className="container yarn-order">
              <div className="row fabric-row">
                <div className="col-md-6" style={{ display: "flex", alignItems: "center" }} data-aos="zoom-in" data-aos-duration="4000" data-aos-delay="500" data-aos-once="true">
                  <div>
                    <span style={short_title}>Our Products</span>
                    <h1 className="bordered_heading" style={main_title}>
                      {post.title}
                    </h1>
                    <br />
                    <br />
                    {
                      post?.productdescriptions?.map((desc) => {
                        return (
                          <>
                            <p>
                              {desc.description}
                              {/* We are supplying premium quality yarn from Pakistan, India, China, Turkey, Egypt, Vietnam, and Indonesia. */}
                            </p>
                            
                          </>
                        )
                      })
                    }
                    {

                      post?.points?.map((item) => {
                        return (
                          <>
                            <p className="fw-bold">
                              {item.title1}
                            </p>
                            <ul className="list-group">
                              {
                                item.subpoints?.map((subPoint) => {
                                  const stringArr = subPoint?.points?.split(':')
                                  return (
                                    <li style={{ listStyleType: "none" }}>
                                      <div style={{ display: "inline-block" }}>
                                        <i class="fa fa-check-circle" style={bullet}></i>
                                      </div>
                                      {
                                        stringArr == null ?
                                          null :
                                          stringArr.length < 2 ?
                                            <span style={listContent}>
                                              {/* {lastWord(post.points)} */}
                                              <span>{stringArr[0]}</span>
                                              {subPoint.description != null && subPoint.description != 'null' ?
                                                <p className="mt-3">
                                                  {subPoint.description}
                                                </p>
                                                :
                                                null
                                              }
                                            </span>
                                            :
                                            <span style={listContent}>
                                              <strong>{stringArr[0]}: </strong>
                                              {/* {lastWord(post.points)} */}
                                              <span>{stringArr[1]}</span>
                                              {subPoint.description != null && subPoint.description != 'null' ?
                                                <p className="mt-3">
                                                  {subPoint.description}
                                                </p>
                                                :
                                                null
                                              }
                                            </span>

                                      }
                                    </li>
                                  )
                                })
                              }
                            </ul>
                          </>
                        )
                      })

                    }


                  </div>
                </div>
                <div className="col-md-6 col-lg-6 pe-0" data-aos="fade-in" data-aos-duration="4000" data-aos-once="true">
                  {
                    post?.images?.length <= 1 ?
                      <img
                        className="slide p-2 fabric-img"
                        src={post?.images && post?.images[0]?.images}
                        alt=""
                        style={{ width: "100%" }}
                      /> :

                      <Fade className="fabric-img" style={{ width: '100%' }} duration={5000} arrows={false} transitionDuration={1000}  >
                        {post?.images?.map((image) => {
                          return (
                            <img
                              className="slide fabric-img"
                              src={image.images}
                              alt=""
                              style={{ width: "100%" }}
                            />
                          )
                        })

                        }
                      </Fade>
                  }
                </div>
              </div>
              <br />
              <div className="row fabric-row">
                <div className="col-md-12" >

                  <h1 data-aos="zoom-in" data-aos-duration="4000" data-aos-once="true" style={{ fontSize: "28px" }}>
                    <strong>{belowPoints?.title1}:</strong>
                  </h1>
                  <p data-aos="zoom-in" data-aos-duration="4000" data-aos-once="true">
                    {belowPoints?.description1}
                  </p>
                  <br />
                  <h1 data-aos="zoom-in" data-aos-duration="4000" data-aos-once="true" style={{ fontSize: "28px" }}>
                    <strong>{belowPoints?.title2}:</strong>
                  </h1>
                  <p data-aos="zoom-in" data-aos-duration="4000" data-aos-once="true">
                    {belowPoints?.description2}
                  </p>

                  <br />
                  <ul className="list-group">
                    <li data-aos="zoom-in" data-aos-duration="4000" data-aos-once="true" style={{ listStyleType: "none" }}>
                      <div style={{ display: "inline-block" }}>
                        <i class="fa fa-check-circle" style={bullet}></i>
                      </div>
                      <span style={listContent}>
                        <strong>{belowPoints?.title3}: </strong>
                        {belowPoints?.description3}
                      </span>
                    </li>
                    <li data-aos="zoom-in" data-aos-duration="4000" data-aos-once="true" className="mt-2" style={{ listStyleType: "none" }}>
                      <div style={{ display: "inline-block" }}>
                        <i class="fa fa-check-circle" style={bullet}></i>
                      </div>
                      <span style={listContent}>
                        <strong>{belowPoints?.title4}: </strong>
                        {belowPoints?.description4}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <br />
            <div className="container-fluid" style={{ background: "#F4FCFF" }}>
              <div className="container-fluid">
                <div>
                  <ProForm
                    image="/media/OnlineInquiry/yarn-inquiry-form-bg.jpg"
                    inquiryForm={true}
                    info="Yarn"
                    tag="Send us your inquiry we will quote you best price"
                    apiType={'yarn'}
                  />
                </div>
              </div>
            </div>
          </section>
        ))}
    </>
  );
}
