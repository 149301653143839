import React from 'react'
import Map from '../Map'

export default function ServedAreas() {
  return (
    <section className='served-areas'>
        <div className="container" >
          <div className=' d-block '> 
            <h1 className='sub-heading text-center'>WHERE WE EXPORT</h1>
            <h1 data-aos="zoom-in" data-aos-duration="4000" data-aos-once="true" className='bordered_heading' style={{margin: "0 auto 46px", display: "table"}}>Our Served Areas</h1>
            </div>
            <p style={{textAlign:"center", marginBottom: "39px"}} data-aos="zoom-in" data-aos-duration="4000" data-aos-once="true">We accomplish Customer Satisfaction by Exporting Textiles in:<strong> Europe </strong>(Germany, Italy, Belgium, Spain, Switzerland, Netherland, Czech Republic, Poland, UK, Portugal, Bulgaria). <strong>Fareast</strong> (China, Taiwan, Hong Kong, Bangladesh, Malaysia, Thailand). <strong>South America</strong> (Guatemala, Chile, Brazil, Mexico)</p>
            <Map/>
        
        </div>
    </section>
  )
}
