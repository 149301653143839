import React, { useEffect, useState } from 'react'
import AxiosInstance from '../../Axios';
import styles from './About.module.css'
import Ceo from './Ceo';

export default function History() {

  const [posts, setPosts] = useState([]);


  useEffect(() => {
    AxiosInstance.get(`api/about-page`)
      .then((res) => {
        setPosts(res.data.successData.history);
      })
      .catch((error) => {
        //  console.log(error.message);
      });
  }, []);


  return (
    <>
      {posts
        .filter((post) => post.id == 132)
        .map((post) => (
          <section key={post.id} id='history' data-aos="fade-in" data-aos-duration="4000" data-aos-once="true">
            <div className="container mt-5">
              <span className={`sub-heading text-center display-block`}>Established In 1992</span>
              <div style={{ display: "flex", justifyContent: `center`, alignItems: `center` }}>
                <h1 className={`bordered_heading text-center`}>{post.title}</h1>
              </div>
              <p className='text-center animate__animated animate__fadeIn animate__slower mt-2'>
                {post.description}
              </p>
              <br />
              <br />


            </div>

          </section>
        ))}
    </>
  )
}