import React, { useEffect, useState } from 'react'
import AxiosInstance from '../../Axios'
import Header from '../../Components/Header'
import NavigationHeader from '../../Components/NavigationHeader'
import Goal from './Goal'
import History from './History'
import styles from './About.module.css'
import { Helmet } from 'react-helmet'
import Ceo from './Ceo'


export default function About() {
  document.title = "About - Skytex";


  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(true)


  useEffect(() => {
    AxiosInstance.get(`api/about-page`)
      .then((res) => {
        console.log('about resp', res.data.successData.slider);
        setPosts(res.data.successData.cards);
        //   setPosts(res.data);
      })
      .catch((error) => {
        setError(error.message);
        console.log(error.message);
      });
  }, []);

  const aboutCard = posts.length
    ? posts
      .sort((a, b) => a["id"] - b["id"])
      .map((post) => (
        <div key={post.id} className={`col-md-6`} id='mission' style={{textAlign: "left" }}>
          <img src={post.image} alt="" />
          <h1 className={`${styles.main_title} mt-4`} style={{ fontSize: "30px" }}>{post.title}</h1>
          <div style={{ display: "flex", justifyContent: "left", alignItems: "post.left" }}>
            <p>{post.description}</p>
          </div>
        </div>
      )) : null;

  return (
    <section>
      <Helmet>
        <title>About-Skytex</title>
      </Helmet>
      <Header />
      <NavigationHeader page="About" background="/media/About/about-bg.jpg" />
      <History />
      <Ceo />
      <div className="container" id='aboutcards'>
        <div className="row mt-5">
          {aboutCard}
          {/* <Goal id="MISSION" gName="MISSION" columns="6" gAlignment="left" gIcon="Mission.png" gContent="“To be better leading partner and top exporter of high quality Yarn, Fabric, Home Textile & Garments across the globe”"/>
                <Goal id="vision" gName="VISION" columns="6" gAlignment="left" gIcon="Vision.png" gContent="“To become the preferred choice of customers through our services and to be the world’s most trusted partner in textile”"/> */}
          {/* <Goal id="sustainabilty" gName="SUSTAINABILITY" gAlignment="center" columns="12" gIcon="sustainability.png" gContent="Skytex is aware of the changing needs in today's market. Our dynamic team actively involved in research and eager to face new challenges in textile industry we know the need of customers we offer wide variety of demanding Products with new technologies which is why our company is in continuous growth."/> */}
        </div>
      </div>
      <br></br><br></br>
    </section>
  )
}
