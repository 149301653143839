import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick/lib/slider";
import style from "./Home.module.css";
import "../../Sliders.css";
import "./slick.css";
import AxiosInstance from "../../Axios";
import AOS from "aos";
import clsx from "clsx";

export default function HomeCarousel() {
  const [posts, setPosts] = useState([]);
  const [activeSlide, setActiveSlide] = useState(false);
  const [activeSlider, setActiveSlider] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sliderKey, setSliderKey] = useState(Math.random());
  useEffect(() => {
    AxiosInstance.get(`api/home-page`)
      .then((res) => {
        setLoading(false);
        setPosts(res.data.successData.slider);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.message);
      });
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    dotsClass: "button__bar",
    beforeChange: (current, next) => {
      console.log("beforeChange", current, next);
      AOS.refreshHard();
      setActiveSlide((prev) => !prev);
      const activeArr = [];
      if (next !== 0) {
        console.log({ t: "ifffff" });
        activeArr[0] = false;
        setSliderKey(Math.random());
      }
      activeArr[next] = true;
      setActiveSlider(activeArr);
    },
    afterChange: (current) => {
      console.log("afterChange", current);
      AOS.refreshHard();
    },
  };

  useEffect(() => {
    let t = null;
    if (!loading) {
      console.log("if loading false");
      t = setTimeout(() => {
        console.log("timeout");
        setActiveSlider([true]);
        setSliderKey(Math.random());
        setActiveSlide((prev) => !prev);
      }, 1000);
    }
    return () => {
      console.log("unmount");
      setActiveSlider([]);
      setSliderKey(Math.random());
      clearTimeout(t);
    };
  }, [loading]);

  console.log({ activeSlider, activeKey: activeSlide, sliderKey });
  function lastWord(words) {
    var n = words.split(/[\s.]+/);
    return n[n.length - 1];
  }
  const sliderRef = useRef(null);
  const textArray = [
    !loading && (
      <div
        key={sliderKey}
        className={clsx(style.slide_content, activeSlider[0] && style.active)}
      >
        <p data-aos="fade-up" data-aos-duration="500" data-aos-delay="1000" >
          ESTABLISHED IN <span>1992</span>
        </p>
        <h1 className={style.sliderTitle} data-aos="fade-up" data-aos-duration="500" data-aos-delay="2000">WELCOME TO SKYTEX</h1>
        <h2 data-aos="fade-up" data-aos-duration="500" data-aos-delay="3000">Trading & Sourcing Company of Textiles</h2>
      </div>
    ),
    <div
      className={clsx(
        style.slide_content,
        style.secondary_slide,
        activeSlider[1] && style.active2
      )}
    >
      <h1 className={style.sliderTitle}>RELIABLE PARTNER</h1>
      <h2>More than 30 years of Experience</h2>
    </div>,
    <div
      // className={clsx(style.slide_content, activeSlider[2] && style.active)}
      key={activeSlide}
    >
      <h1
        data-aos="slide-up"
        data-aos-delay="50"
        data-aos-duration="2000"
        className={style.sliderTitle}
      >
        EXPORTING VARIETY OF TEXTILES
      </h1>
    </div>,
    <div key={activeSlide}>
      <h1
        className={style.sliderTitle}
        data-aos="slide-up"
        data-aos-delay="50"
        data-aos-duration="2000"
      >
        KNOWN FOR <span>QUALITY PRODUCTS</span>
      </h1>
    </div>,
  ];
  return (
    <div className={style.myContent}>
      <Slider
        autoPlay
        arrows={false}
        {...settings}
        ref={sliderRef}
        className={style.mySlider}
      >
        {posts
          .sort((a, b) => a["id"] - b["id"])
          .map((post) => (
            <div key={post.id} className="homeslide">
              <div
                className={style.Slide1Cap}
                data-aos="zoom-in-right"
                data-aos-delay="8000"
                data-aos-duration="2000"
              >
                {post?.id === 1
                  ? textArray[0]
                  : post?.id === 2
                  ? textArray[1]
                  : post?.id === 3
                  ? textArray[2]
                  : textArray[3]}
              </div>

              {lastWord(post.image) == "mp4" ? (
                <video
                  id="slide1"
                  autoPlay
                  muted
                  loop
                  style={{ width: "100%", height: "100%" }}
                >
                  <source src={post.image} />
                </video>
              ) : (
                <img src={post.image} className={`${style.HomeCarousel_img}`} />
              )}
            </div>
          ))}
      </Slider>
    </div>
  );
}
