import React, { useEffect, useState } from "react";
import HomeStyles from "../Pages/Home/Home.module.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import "../Pages/Home/Home.module.css";
import AxiosInstance from "../Axios";
import clsx from "clsx";
import { useSelector } from "react-redux";

export default function Header() {
  var Tawk_API = Tawk_API || {},
    Tawk_LoadStart = new Date();
  (function () {
    var s1 = document.createElement("script"),
      s0 = document.getElementsByTagName("script")[0];
    s1.async = true;
    s1.src = "https://embed.tawk.to/627b56a77b967b11798ec4a6/1g2oskvp3";
    s1.charset = "UTF-8";
    s1.setAttribute("crossorigin", "*");
    s0.parentNode.insertBefore(s1, s0);
  })();

  const logoReducer = useSelector((state) => state.LogoReducer);
  const tickerReducer = useSelector((state) => state.TickerReducer);
  const ProductReducer = useSelector(state => state.ProductReducer)
  const CategoriesReducer = useSelector(state => state.CategoriesReducer)

  const [mainSection, setMainSection] = useState("/");
  const [stickyNav, setStickyNav] = useState(false);
  const navigate = useNavigate();

  const redirectTo = (page, section) => {
    navigate(page);
    setMainSection(section);
  };


  var navbar = document.getElementById("navbar");
  // Get the offset position of the navbar
  function myFunction() {
    var sticky = navbar?.offsetTop;
    if (window.pageYOffset >= sticky + 90) {
      setStickyNav(true);
    } else {
      setStickyNav(false);
    }
  }
  useEffect(() => {
    window.onscroll = function () {
      myFunction();
    };
  }, [window.pageYOffset]);

  const navbrandLogo = logoReducer?.filter((post, index) => index == 0)
    .map((post) => <img className={stickyNav && HomeStyles.stickyLogo} key={post.id} src={post.image} />);

  return (
    <>
      <div style={{ background: "#00AFF0" }}>
        <div className="container">
          <div
            className="row"

          >
            <div className="col-md-12">
              {
                tickerReducer?.filter((post, index) => index == 0)
                  .map((post) => (
                    <marquee
                      key={post.id}
                      className={`${HomeStyles.price_marquee} text-white`}
                      scrollamount="5"
                    >
                      {post.ticker_text}
                    </marquee>
                  ))
              }
            </div>
          </div>
        </div>
      </div>
      <nav
        className={clsx(
          "navbar navbar-expand-lg bg-white",
          stickyNav && HomeStyles.sticky
        )}
        id="navbar"
      >
        <div className="container">
          <Link className="navbar-br" to="/">
            {navbrandLogo}
          </Link>
          <button
            className={`${HomeStyles.nav_toggler} navbar-toggler p-0`}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <ul className="p-0 m-0" style={{ listStyle: "none" }}>
              <li style={{ height: "15px" }}>
                <span className={`${HomeStyles.nav_button3}`}></span>
                <span className={`${HomeStyles.nav_button2}`}></span>
                <span className={`${HomeStyles.nav_button3}`}></span>
              </li>
              <li style={{ height: "15px" }}>
                <span className={`${HomeStyles.nav_button2}`}></span>
                <span className={`${HomeStyles.nav_button3}`}></span>
                <span className={`${HomeStyles.nav_button1}`}></span>
              </li>
              <li style={{ height: "15px" }}>
                <span className={`${HomeStyles.nav_button3}`}></span>
                <span className={`${HomeStyles.nav_button2}`}></span>
                <span className={`${HomeStyles.nav_button3}`}></span>
              </li>
            </ul>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul
              className={clsx(
                "navbar-nav me-auto mb-2 mb-lg-0",
                stickyNav && HomeStyles.stickyAnchors
              )}
            >
              <li className={`nav-item dropdown ${HomeStyles.first_item}`}>
                <Link
                  className={`nav-link ${HomeStyles.add_plus}`}
                  to="/about-us"
                  id="navbarDropdown"
                  role="button"
                  aria-expanded="false"
                >
                  About
                </Link>
                <ul className="dropdown-menu animated-dropdown slideIn" aria-labelledby="navbarDropdown">
                  {/* <li><button className="dropdown-item text-white" onClick={()=>redirectTo("about-us","historysection")}>History</button></li> */}
                  <li>
                    <HashLink
                      className="dropdown-item text-white"
                      to="/about-us/#history"
                    >
                      History
                    </HashLink>
                  </li>
                  <li>
                    <HashLink
                      className="dropdown-item text-white"
                      to="/about-us/#ceo-message"
                    >
                      CEO Message
                    </HashLink>
                  </li>
                  <li>
                    <HashLink
                      className="dropdown-item text-white"
                      to="/about-us/#mission"
                    >
                      Mission
                    </HashLink>
                  </li>
                  <li>
                    <HashLink
                      className="dropdown-item text-white"
                      to="/about-us/#mission"
                    >
                      Vision
                    </HashLink>
                  </li>
                  <li>
                    <HashLink
                      className="dropdown-item text-white"
                      to="/about-us/#mission"
                    >
                      Sustainablity
                    </HashLink>
                  </li>
                </ul>
              </li>
              <li className={`nav-item dropdown ${HomeStyles.nav_items}`}>
                <a
                  className={`nav-link ${HomeStyles.add_plus}`}
                  href="javascript:void(0);"
                  id="navbarDropdown"
                  role="button"
                  aria-expanded="false"
                >
                  Products
                </a>
                <ul className="dropdown-menu animated-dropdown slideIn" aria-labelledby="navbarDropdown">
                  {
                    ProductReducer?.map((product, pIndex) => {
                      const arrayStr = product?.title?.split(' ');
                      return (
                        <li className={`${HomeStyles.dropdown} `}>
                          <Link
                            className={`${HomeStyles.drdwn} dropdown-item text-white second-level-plus`} id="navbarDropdown"
                            role="button"
                            aria-expanded="false"
                            to={{
                              pathname: `/product/${product?.id}/${product?.title}`,
                              // search: arrayStr.length > 1 ? `${arrayStr[0]}-${arrayStr[1]}` : `${arrayStr[0]}`
                            }}
                          // state={{ product: product, category: null }}
                          >
                            {product?.title}
                          </Link>
                          <ul
                            className={`${HomeStyles.dropdown_content} animated-dropdown slideIn `}
                            aria-labelledby="nabarDropdown"
                          >
                            {
                              product.categories?.map((category, cIndex) => {
                                const arrayStr1 = category?.title?.split(' ');
                                return (
                                  <li>
                                    {(product?.title == 'Yarn' || product?.title == 'yarn' || product?.id == 39) ?
                                      <Link
                                        className="dropdown-item text-white"
                                        to={{
                                          pathname: `/yarn-category/${product?.id}/${product?.title}/${category?.id}`,
                                          // search: arrayStr.length > 1 ? `${arrayStr[0]}-${arrayStr[1]}` : `${arrayStr[0]}`,
                                          hash: arrayStr1?.length > 1 ? `${arrayStr1[0]}-${arrayStr1[1]}` : `${arrayStr1[0]}`
                                        }}
                                        // state={{ product: product, category: category }}
                                      >
                                        {category?.title}
                                      </Link> :
                                      <HashLink
                                        className="dropdown-item text-white"
                                        to={{
                                          pathname: `/product/${product?.id}/${product?.title}`,
                                          // search: arrayStr.length > 1 ? `${arrayStr[0]}-${arrayStr[1]}` : `${arrayStr[0]}`,
                                          hash: arrayStr1?.length > 1 ? `${arrayStr1[0]}-${arrayStr1[1]}` : `${arrayStr1[0]}`
                                        }}
                                        // state={{ product: product, category: category }}
                                      >
                                        {category?.title}
                                      </HashLink>
                                    }
                                  </li>
                                )
                              })
                            }

                            {/* <li>
                          <Link
                            className="dropdown-item text-white"
                            to="/cellulose-blended-yarn"
                          >
                            Cellulose (100% & Blended Yarn)
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item text-white"
                            to="/synthetic-blended-yarn"
                          >
                            Synthetic (100% & Blended Yarn)
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item text-white"
                            to="/melange-yarn"
                          >
                            Melange Yarn
                          </Link>
                        </li> */}
                          </ul>
                        </li>
                      )
                    })
                  }
                  {/* <li className={`${HomeStyles.dropdown} `}>
                    <Link
                      className={`${HomeStyles.drdwn} dropdown-item text-white `}
                      to="/yarn"
                    >
                      Yarn
                    </Link>
                    <ul
                      className={`${HomeStyles.dropdown_content}  animated-dropdown slideIn `}
                      aria-labelledby="nabarDropdown"
                    >
                      <li>
                        <Link
                          className="dropdown-item text-white"
                          to="/cotton-blended-yarn"
                        >
                          Cotton (100% & Blended Yarn)
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item text-white"
                          to="/cellulose-blended-yarn"
                        >
                          Cellulose (100% & Blended Yarn)
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item text-white"
                          to="/synthetic-blended-yarn"
                        >
                          Synthetic (100% & Blended Yarn)
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item text-white"
                          to="/melange-yarn"
                        >
                          Melange Yarn
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li className={`${HomeStyles.dropdown} `}>
                    <Link
                      className={`${HomeStyles.drdwn} dropdown-item text-white `}
                      to="/fabric"
                    >
                      Fabric
                    </Link>
                    <ul
                      className={`${HomeStyles.dropdown_content} `}
                      aria-labelledby="nabarDropdown"
                    >
                      <li>
                        <Link
                          className="dropdown-item text-white"
                          to="/fabric/#Grey"
                        >
                          Grey Fabric
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item text-white"
                          to="/fabric/#Bleached"
                        >
                          Bleached Fabric
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item text-white"
                          to="/fabric/#Dyed"
                        >
                          Dyed Fabric
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item text-white"
                          to="/fabric/#Printed"
                        >
                          Printed Fabric
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item text-white"
                          to="/fabric/#Denim"
                        >
                          Denim Fabric
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item text-white"
                      to="/home-textile"
                    >
                      Home Textile
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item text-white" to="/garments">
                      Garments
                    </Link>
                  </li> */}
                </ul>
              </li>
              <li className={`nav-item dropdown ${HomeStyles.nav_items}`}>
                <Link
                  className={`nav-link ${HomeStyles.add_plus}`}
                  to="/our-services"
                  id="navbarDropdown"
                  role="button"
                  aria-expanded="false"
                >
                  Services
                </Link>
                <ul className="dropdown-menu animated-dropdown slideIn" aria-labelledby="navbarDropdown">
                  <li>
                    <HashLink
                      className="dropdown-item text-white"
                      to="/our-services/#Admirable"
                    >
                      Admirable Sourcing
                    </HashLink>
                  </li>
                  <li>
                    <HashLink
                      className="dropdown-item text-white"
                      to="/our-services/#Quality"
                    >
                      Quality Control Policy
                    </HashLink>
                  </li>
                  <li>
                    <HashLink
                      className="dropdown-item text-white"
                      to="/our-services/#Product"
                    >
                      Product Development
                    </HashLink>
                  </li>
                  <li>
                    <HashLink
                      className="dropdown-item text-white"
                      to="/our-services/#Sampling"
                    >
                      Sampling
                    </HashLink>
                  </li>
                  <li>
                    <HashLink
                      className="dropdown-item text-white"
                      to="/our-services/#Customer"
                    >
                      Customer Meeting Coordination
                    </HashLink>
                  </li>
                  <li>
                    <HashLink
                      className="dropdown-item text-white"
                      to="/our-services/#After"
                    >
                      After Sale Services
                    </HashLink>
                  </li>
                </ul>
              </li>
              <li className={`nav-item dropdown ${HomeStyles.nav_items}`}>
                <Link
                  className={`nav-link ${HomeStyles.add_plus}`}
                  to="#"
                  id="navbarDropdown"
                  role="button"
                  aria-expanded="false"
                >
                  Our Activities
                </Link>
                <ul className="dropdown-menu animated-dropdown slideIn" aria-labelledby="navbarDropdown">
                  <li>
                    <Link
                      className="dropdown-item text-white"
                      to="/connection-with-textile-fairs"
                    >
                      Connection With Textile Fairs
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item text-white" to="/blog">
                      Blog
                    </Link>
                  </li>
                </ul>
              </li>
              <li className={`nav-item  ${HomeStyles.nav_items}`}>
                <Link
                  className={`nav-link ${HomeStyles.NormalNav} me-0`}
                  to="/careers"
                >
                  Career
                </Link>
              </li>
              <li className={`nav-item dropdown ${HomeStyles.nav_items}`}>
                <Link
                  className={`nav-link ${HomeStyles.add_plus}`}
                  ps-0
                  to="#"
                  id="navbarDropdown"
                  role="button"
                  aria-expanded="false"
                >
                  Online Inquiry
                </Link>
                <ul className="dropdown-menu animated-dropdown slideIn" aria-labelledby="navbarDropdown">
                  <li>
                    <Link
                      className="dropdown-item text-white"
                      to="/yarn-inquiry-form"
                    >
                      Yarn
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item text-white"
                      to="/fabric-inquiry-form"
                    >
                      Fabric
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item text-white"
                      to="/general-inquiry"
                    >
                      General Inquiry
                    </Link>
                  </li>
                </ul>
              </li>
              <li className={`nav-item ${HomeStyles.nav_items}`}>
                <Link
                  className={`nav-link ${HomeStyles.NormalNav}`}
                  to="/contact"
                >
                  Contact
                </Link>
              </li>
              {/* <li className="nav-item">
                <Translator/>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
