import React from "react";
import { Link } from "react-router-dom";

export default function NavigationHeader(props) {
  return (
    <div
      className={"header"}
      style={{ backgroundImage: `url(${props.background})` }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <h1 className="text-white">{props.page}</h1>
            <ol className={`custombread breadcrumb`}>
              <li
                className="breadcrumb-item m-0 text-white"
                style={{ fontSize: "22px" }}
              >
                <Link className="text-decoration-none text-white" to="/">
                  Skytex
                </Link>
              </li>
              <li
                className="breadcrumb-item active m-0 text-white"
                aria-current="page"
                style={{ fontSize: "22px" }}
              >
                {props.page}
              </li>
            </ol>
          </div>
          {props.reqBtn ? (
            <div
              className="col-md-4"
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <Link
                to="/general-inquiry"
                className="btn btn-info btn-lg text-white"
                style={{
                  background: "#00aff0eb",
                  fontSize: "24px",
                  padding: "6px 25px",
                  borderRadius: "10px",
                }}
              >
                Request Sample &nbsp; <i class="fa fa fa-angle-right mt-1"></i>
              </Link>
            </div>
          ) : (
            false
          )}
        </div>
      </div>
    </div>
  );
}
