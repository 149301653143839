import React, { useEffect, useState } from "react";
import AxiosInstance from "../../Axios";
import styles from "./Home.module.css";

const Offices = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    AxiosInstance.get(`api/home-page`)
      .then((res) => {
        setPosts(res.data.successData.WhereWeAre);
      })
      .catch((error) => {
        // console.log(error.message);
      });
  }, []);

  const office = posts.length
    ? posts
        .sort((a, b) => a["id"] - b["id"])
        .map((post) => (
          <div key={post.id} className={styles.flex_countries}>
            <div className="">
              <img
                src={post.image}
                className={`p-1 ${styles.flex_countries_img} `}
                alt="Pakistan"
              />
            </div>
            <h3 className="text-white text- mt-3">{post.title}</h3>
          </div>
        ))
    : null;

  return (
    <section className='offices'>
      <div className='offices-bg'
        style={{
          height: "30vh + auto",
          background: `linear-gradient(rgba(21, 21, 21, 0.7), rgba(21, 21, 21, 0.7)), url("/media/Home/skytex-offices-bg.jpg")`,
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
        }}
      >
        <div
          className={`container`}          
        >
          <div
          className={`row`}          
        >
            <div
              className="col-12 col-md-3 col-lg-3 pe-0"
              style={{display: "flex", alignItems: "center" }}
              data-aos="zoom-in-right"
              data-aos-duration="4000"
              data-aos-once="true"
            >
              <div>
              <h1
                className="sub-heading text-white"                
              >
                OFFICES
              </h1>
              <h2
                className="bordered_heading mb-0 text-white"                
              >
                Where We Are?
              </h2>
              </div>
            </div>
            <div className="col-12 col-md-9 col-lg-9" data-aos="zoom-in" data-aos-duration="4000" data-aos-delay="1000" data-aos-once="true">
              <div>
                <div className={styles.flex_countries_main}>{office}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Offices;
