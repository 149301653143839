import axios from "axios";

export const baseURL = "https://skytex.com.pk/admin/";

const AxiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 18000,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
});

export default AxiosInstance;
