import React, { useEffect, useState } from "react";
import AxiosInstance from "../../Axios";
import Header from "../../Components/Header";
import NavigationHeader from "../../Components/NavigationHeader";
import FairsCard from "./FairsCard";
import styles from "./TextileFairs.module.css";

export default function TextileFairs() {
  document.title = "Connection with Textile Fairs - Skytex";
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    AxiosInstance.get(`api/textile-fairs`)
      .then((res) => {
        setPosts(res.data.successData.textile);
      })
      .catch((error) => {
        // console.log(error.message);
      });
  }, []);

  const fairs = posts.length
    ? posts
        .sort((a, b) => a["id"] - b["id"])
        .map((post) => (
          <div key={post.id} className="col-lg-3 col-md-6 col-sm-6 col-xs-6 mt-4" data-aos="fade-in" data-aos-duration="4000" data-aos-delay="1000" data-aos-once="true">
            <div className="wobble-vertical-on-hover"
              style={{
                boxShadow: "0px 3px 10px 0px rgb(0 0 0 / 12%)",
                padding: "15px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={post.image}                  
                  className=" text-center"
                />
              </div>
              <br />
              <span className={`${styles.short_title} text-center`}>
                {post.title}
              </span>
            </div>
          </div>
        ))
    : null;

  return (
    <section id="fabric">
      <Header />
      {posts
        .filter((post) => post.id == 159)
        .map((post) => (
          <>
            <NavigationHeader
              page={post.title}
              background="/media/Fairs/textile-bg.jpg"
            />
            <div className="container our-activities section-padding-50" data-aos="fade-in" data-aos-duration="4000" data-aos-once="true">
              <span
                className={` bordered_heading display-block`}
              >
                {post.title}
              </span>

              <p>
                {post.description}
                <br />
                Skytex has the tradition to attend these main fairs:
              </p>
              <div className="row textfairs">
                {fairs}
              </div>
            </div>
          </>
        ))}
    </section>
  );
}
