import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import AxiosInstance from "../../Axios";
import Header from "../../Components/Header";
import NavigationHeader from "../../Components/NavigationHeader";
import Form from "./Form";
import InfoCard from "./InfoCard";

export default function Contact() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    AxiosInstance.get(`api/footer`)
      .then((res) => {
        setPosts(res.data.successData.company);
      })
      .catch((error) => {
        // console.log(error.message);
      });
  }, []);

  const contactInfo = posts.length
    ? posts.map((post) => (
        <>
        <Helmet>
          <title>Contact Us - Skytex</title>
        </Helmet>
          <NavigationHeader
            page={post.name}
            background="/media/Contact/contact-us-bg.jpg"
          />

          <div key={post.id} className="container first-section">
            <div className="row">
              <InfoCard
                icon="fa-map-marker"
                content={post.address}
                address="Office Address"
              />
              <InfoCard
                icon="fa-phone"
                content={post.phone}
                address="Phone Number"
              />
              <InfoCard
                icon="fa-envelope-o"
                content={post.email}
                address="Email Address"
              />
            </div>

          </div>
        </>
      ))
    : null;

  return (
    <section>
      <Header />
      {contactInfo}

      <div
        className="container-fluid"
        style={{ width: "100%", margin: "auto" }}
      >
        <Form
        info={'contact-us'}
          image="/media/OnlineInquiry/general-Inquiry-Form-Image.jpg"
          tag="Get In Touch"
        />
      </div>

      <iframe
        style={{ width: "100%", height: "400px" }}
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6807.879615490201!2d74.265791!3d31.443323999999997!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3919019fdf9a6c4d%3A0x5bf9fd330652d532!2sAirline%20Society%2C%20Lahore%2C%20Punjab%2C%20Pakistan!5e0!3m2!1sen!2sus!4v1653673185976!5m2!1sen!2sus"
        title="Airline Housing Society"
        aria-label="Airline Housing Society"
      ></iframe>
    </section>
  );
}
