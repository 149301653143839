const setLogo = (data) => {
    return({
        type:'logo',
        payload:data
    })
}
const setTicker = (data) => {
    return({
        type:'ticker',
        payload:data
    })
}

const setProducts = (data) => {
    return({
        type:'products',
        payload:data
    })
}

const setCategories = (data) => {
    return({
        type:'categories',
        payload:data
    })
}

export {setLogo,setTicker,setProducts,setCategories}