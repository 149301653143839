import React from 'react'

export default function InfoCard(props) {
  
  const Icon = {
    color: "#00AFF0",
    fontSize: "65px",
    background:"#00AFF030",
    borderRadius:"50%",
    padding:"8px 12px"
  };
  const addressIcon = {display:"flex",justifyContent:"center",alignItems:"center"};
  return (
    <div className="col-md-4">
      <div className="card border-0" style={{width:"100%",height:"300px",background:"#F4FCFF"}}>
        {/* <img src="..." className="card-img-top" alt="..."/> */}
        <div className="card-body" style={{padding:"50px 0px 0px 0px"}}>
            <div style={addressIcon}>
                <i class={`fa ${props.icon}`} style={Icon}></i>
            </div>
            <h5 className="card-title text-center" style={{margin: "23px 3px 10px 3px",fontWeight:"bold"}}>{props.address}</h5>
            <p className="card-text text-center" style={{marginBottom:"35px"}}>{props.content}</p>
        </div>
      </div>
    </div>
  )
}
