function LogoReducer (state=null,action) {
    switch(action.type){
        case 'logo':{
            return action.payload;
        }
        default: {
            return state;
        }
    }
}

function TickerReducer (state=null,action) {
    switch(action.type){
        case 'ticker':{
            return action.payload;
        }
        default: {
            return state;
        }
    }
}

function ProductReducer (state=null,action) {
    switch(action.type){
        case 'products':{
            return action.payload;
        }
        default: {
            return state;
        }
    }
}

function CategoriesReducer (state=null,action) {
    switch(action.type){
        case 'categories':{
            return action.payload;
        }
        default: {
            return state;
        }
    }
}
export {LogoReducer,TickerReducer,ProductReducer,CategoriesReducer}