import React, { useEffect, useState } from "react";
import "./Footer.css";
import { GoLocation } from "react-icons/go";
import { BsTelephone } from "react-icons/bs";
import { MdOutlineMail } from "react-icons/md";
import { Link } from "react-router-dom";
import AxiosInstance from "../Axios";
import { HashLink } from 'react-router-hash-link';
import { useSelector } from "react-redux";

export default function Footer() {
  const footerimg = `url("/media/Home/footer-bg.jpg")`;
  const ProductReducer = useSelector(state => state.ProductReducer)

  const [posts, setPosts] = useState([]);
  const [fproduct, setFproducts] = useState([]);
  const [fservices, setServices] = useState([]);

  useEffect(() => {
    AxiosInstance.get(`api/footer`)
      .then((res) => {
        setPosts(res.data.successData.company);
        setFproducts(res.data.successData.product);
        // setServices(res.data.successData.main_Services);
        setServices(res.data.successData.home_Services);
      })
      .catch((error) => {
        // console.log(error.message);
      });
  }, []);

  const contactInfo = posts.length
    ? posts.map((post) => (
      <div key={post.id} className="contacts">
        <p className="section-title">{post.name}</p>
        <ul className="fourth-ul">
          <li className="footer-links">
            <GoLocation className="contact-icon" />
            <span>{post.address}</span>
          </li>
          <li>
            <BsTelephone className="contact-icon" />
            <a className="footer-contact ps-0" href="tel:+92 300 8425475">
              {post.phone}
            </a>
          </li>
          <li className="email">
            <MdOutlineMail className="contact-icon" />
            <a
              className="footer-contact ps-0"
              href="mailto:skytex@skytex.com.pk"
            >
              {post.email}
            </a>
          </li>
        </ul>
      </div>
    ))
    : null;

  const footerproduct =
    ProductReducer?.map((product) => {

      return (
        <ul key={product?.id} className="nav flex-column">
          <li className="footer-link ">
            <Link
              className={"inner-link p-0"}
              to={{
                pathname: "/product",
                // search: arrayStr.length > 1 ? `${arrayStr[0]}-${arrayStr[1]}` : `${arrayStr[0]}`
              }}
              state={{ product: product, category: null }}

            >
              <span className="footer-span">&gt;</span> {product?.title}
            </Link>
          </li>
        </ul>
      )
    })

  const footerServices = fservices.length
    ? fservices.map((fservices) => {
      const a = fservices.title;
      const b = a.split(' ')
      const c = b[0]
      // console.log(c);
      return (
        <ul key={fservices.id} className="nav flex-column">
          <li className="footer-link ">
            <HashLink to={`/our-services/#${c}`} className="inner-link p-0">
              <span className="footer-span">&gt;</span> {fservices.title}
            </HashLink>
          </li>
        </ul>
      )
    })
    : null;

  return (
    <>
      <div
        className="footer-img container-fluid "
        style={{
          paddingLeft: "0px",
          width: "100%",
          height: "100%",
          background: footerimg,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <footer className="container">
          <div className="row footer-top">
            <div className="col-12 col-lg-4 col-md-6">
              <p className="section-title">Future Cotton Prices</p>
              <p className="prices">
                Untitled October 20, 2021 ▲ 88.31 0.08 (0.09%) Update 29/07/2018
                01:00:21 Buy US Cotton #2 Now Sell US Cotton #2 Now52Wk High0
                Since 04/07/22) 0% 52Wk Low0 Since 04/07/22) Infinity% 20-Day
                Average 0 200-Day Average 0 14-Day Rel Strength 100 14-Day
                Stochastic 100 (Feed generated with FetchRSS)
              </p>
            </div>
            <div className="col-12 col-lg-2 col-md-6">
              <p className="section-title">Our Products</p>
              {footerproduct}
            </div>

            <div className="col-12 col-lg-3 col-md-6">
              <p className="section-title">Our Services</p>
              {footerServices}
            </div>

            {/* <div className="col-2"> */}
            <div className="col-12 col-lg-3 col-md-6 fourthCol">
              {contactInfo}
              <div class="toolbar-sl-share">
                <ul class="clearfix">
                  <li> <a href="https://www.facebook.com/skytexlahore" target="_blank" rel="noopener"><i class="fa fa-facebook-f"></i></a> </li>
                  <li> <a href="#" target="_blank" rel="noopener"><i class="fa fa-twitter"></i></a> </li>
                  <li> <a href="https://www.linkedin.com/company/skytex-pakistan" target="_blank" rel="noopener"><i class="fa fa-linkedin"></i></a></li>
                  <li> <a href="https://www.instagram.com/skytexpakistan/" target="_blank" rel="noopener"><i class="fa fa-instagram"></i></a> </li>
                </ul>
              </div>
            </div>

          </div>

          <div className="copyright mt-4 border-top text-center">
            <p className="text-center mb-0">
              &copy; 2022 SKYTEX. All rights reserved
            </p>
            {/* <span style={{color: "transparent", position:"absolute", bottom:"0", left:"18%" }}>Designed by:
            Web Design & Development Company Lahore (Ranglerz)
            </span> */}
          </div>
        </footer>
      </div>
    </>
  );
}
