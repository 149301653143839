import React, { useEffect, useState } from 'react'
import { BrowserView, MobileView } from 'react-device-detect'
import AxiosInstance from '../../Axios'
import Header from '../../Components/Header'
import NavigationHeader from '../../Components/NavigationHeader'
import ServiceCard from './ServiceCard'
import styles from './Services.module.css'
export default function Services() {
  document.title = "Services - Skytex";

  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(true)

  useEffect(() => {
    AxiosInstance.get(`api/services-content`)
      .then((res) => {
        setPosts(res.data.successData.main_services);
      })
      .catch((error) => {
        setError(error.message);
        console.log(error.message);
      });
  }, []);

  const services = posts.length
    ? posts
      .sort((a, b) => a["id"] - b["id"])
      .map((post) => {
        const a = post.title;
        const b = a.split(' ')
        const c = b[0]
        console.log(c)
        return (
          <section id={`${c}`} key={post.id}>

            {/* <div  className="row" id={props.id}> */}
            <div className="row" >
              {(post.id == 136) || (post.id == 138) || (post.id == 140) ? (
                // {props.alignment == "left-right" ? (
                <>
                  <div className="col-md-6 p-0 img services-image" data-aos="fade-in" data-aos-duration="4000" data-aos-once="true" style={{ background: `url(${post.image}) no-repeat`, backgroundSize: 'cover' }}>
                    <img src={post.image} width="100%" alt="" />
                  </div>
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} className="col-md-6 pt-5 pb-5 px-4 services-text" data-aos="zoom-in" data-aos-duration="4000" data-aos-delay="500" data-aos-once="true">
                    <div>
                      <h2 className={`bordered_heading`}>{post.title}</h2>
                      <p>
                        {post.description}
                      </p>
                    </div>
                  </div>
                </>
              ) : <>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} className="col-md-6 pt-5 pb-5 px-4 services-text" data-aos="zoom-in" data-aos-duration="4000" data-aos-delay="500" data-aos-once="true">
                  <div>
                    <h2 className={`bordered_heading`}>{post.title}</h2>
                    <p>
                      {post.description}
                    </p>
                  </div>

                </div>
                <div className="col-md-6 p-0 img services-image" data-aos="fade-in" data-aos-duration="4000" data-aos-once="true" style={{ background: `url(${post.image}) no-repeat`, backgroundSize: 'cover' }}>
                  <img src={post.image} width="100%" alt="" />
                </div>
              </>}

            </div>
          </section>
        )
      }) : null;

  return (
    <section>
      <Header />
      <NavigationHeader page="Services" background="/media/Services/services-bg-1.jpg" />
      <div>
        <div className="container section-padding-50">
          <span className={`${styles.short_title} text-center display-block`}>Work With Us</span>
          <div style={{ display: "flex", justifyContent: `center`, alignItems: `center` }}>
            <h1 className={`bordered_heading text-center`}>Our Spectrum Services</h1>
          </div>

          <p className='text-center'>We offer a full spectrum of sourcing services to help customers take their business to the next level.</p>

          {services}

        </div>
      </div>

    </section>
  )
}