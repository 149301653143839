import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import NavigationHeader from "../../Components/NavigationHeader";
import ProForm from "./ProForm";
import AxiosInstance from "../../Axios";
import { Helmet } from "react-helmet";
import 'react-slideshow-image/dist/styles.css'
import { Fade } from 'react-slideshow-image';
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

export default function YarnCategory() {

  const route = useLocation();
  const ProductReducer = useSelector(state => state.ProductReducer)
  const params = useParams()

  const [posts, setPosts] = useState([]);
  const [category1, setCategory1] = useState([]);
  const [belowPoints, setBelowPoints] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0)
    const product = ProductReducer?.find((item) => item?.id == params?.product_id)
    const category = product?.categories?.find((item) => item?.id == params?.category_id)
    console.log('thisssss',product)
    console.log(category)
    document.title = `${category?.title} - Skytex`;
    setPosts(product?.categories)
    setCategory1(category?.title);
    AxiosInstance.get(`api/count-range`)
      .then((res) => {
        setBelowPoints(res.data.successData.data[0]);
      })
      .catch((error) => {
        //  console.log(error.message);
      });
  }, [ProductReducer,route]);

  const short_title = {
    color: "#595959",
    fontSize: "19px",
    fontWeight: "bold",
    marginBottom: "10px",
    display: "block",
  };

  const main_title = {
    fontSize: "46px",
    fontWeight: "bold",
    color: "#595959",
  };

  const listContent = {
    color: "#444444",
    fontSize: "22px",
    textAlign: "justify",
  };

  const bullet = {
    color: "#00AFF0",
    fontSize: "20px",
  };

  return (
    <>
      <Helmet>
        <title></title>
      </Helmet>
      <section>
        <Header />
        <NavigationHeader
          page={(category1 == '100% Cotton Yarn' || category1 == 'Cotton Blended Yarn') ? `Cotton (100% & Blended Yarns)` : category1}
          reqBtn
          background={"/media/Products/Yarn/yarn-product-bg.jpg"}
        />
        <br />
        <br />
        <br />

        <div className="container">

          {
            posts?.filter((item) => {
              return item?.id == params?.category_id
            })?.map((item, index) => {
              const even = index % 2 == 0;
              return (
                <div className="row fabric-row" style={{ flexDirection: even ? 'row' : 'row-reverse' }}>
                  <div className="col-md-6" data-aos="zoom-in" data-aos-duration="4000" data-aos-once="true">
                    <h1 className="bordered_heading">
                      {item.title}
                    </h1>

                    {item?.points?.map((point) => (

                      <>
                        {point?.title1 == null || point?.title1 == 'null' ? null :
                          <p className="fw-bold">
                            {point?.title1}
                          </p>
                        }
                        <ul className="list-group">
                          {
                            point?.subpoints?.map((subPoint) => {
                              const stringArr = subPoint.points?.split(':')

                              return (


                                <li style={{ listStyleType: "none" }}>
                                  <div
                                    style={{ display: "inline-block" }}
                                  >
                                    <i class="fa fa-check-circle" style={bullet}></i>

                                  </div>

                                  {
                                    stringArr == null ?
                                      null :
                                      stringArr.length < 2 ?
                                        <span style={listContent}>
                                          {/* {lastWord(post.points)} */}
                                          <span>{stringArr[0]}</span>
                                          {subPoint.description != null && subPoint.description != 'null' ?
                                            <p className="mt-3">
                                              {subPoint.description}
                                            </p>
                                            :
                                            null
                                          }
                                        </span>
                                        :
                                        <span style={listContent}>
                                          <strong>{stringArr[0]}: </strong>
                                          {/* {lastWord(post.points)} */}
                                          <span>{stringArr[1]}</span>
                                          {subPoint.description != null && subPoint.description != 'null' ?
                                            <p className="mt-3">
                                              {subPoint.description}
                                            </p>
                                            :
                                            null
                                          }
                                        </span>

                                  }

                                </li>

                              )
                            })
                          }
                        </ul>
                      </>

                    ))
                    }
                    <br />
                  </div>
                  <div className="col-md-6 col-lg-6 pe-0" data-aos="fade-in" data-aos-duration="4000" data-aos-once="true" style={{ display: "flex", alignItems: "center" }}>

                    {

                      item?.id == 104 || item?.id == 103 || item?.id == 102 ?
                        <div style={{ flexDirection: 'column' }} >
                          {
                            item?.images?.map((image) => {
                              return (
                                <img
                                  className="slide fabric-img"
                                  src={image.images}
                                  alt=""
                                  style={{ width: "100%", marginBottom: '20px' }}
                                />
                              )
                            })
                          }
                        </div>


                        :
                        item?.images?.length <= 1 ?
                          <img
                            className="slide fabric-img"
                            src={item?.images && item?.images[0]?.images}
                            alt=""
                            style={{ width: "100%" }}
                          /> :

                          <Fade className="fabric-img" style={{ width: '100%' }} duration={5000} arrows={false} transitionDuration={1000}  >
                            {item?.images?.map((image) => {
                              return (
                                <img
                                  className="slide"
                                  src={image.images}
                                  alt=""
                                  style={{ width: "100%" }}
                                />
                              )
                            })

                            }
                          </Fade>
                    }




                  </div>
                </div>

              )
            })

          }





          <br></br>
          <div data-aos="zoom-in" data-aos-duration="4000" data-aos-once="true">
            <h2 style={{ fontSize: "28px" }}>
              <strong>{belowPoints?.title}:</strong>
            </h2>
            <p>
              {belowPoints?.description}
            </p>
          </div>
          <h1 data-aos="zoom-in" data-aos-duration="4000" data-aos-once="true" style={{ fontSize: "28px" }}>
            <strong>{belowPoints?.title1}:</strong>
          </h1>
          <p data-aos="zoom-in" data-aos-duration="4000" data-aos-once="true">
            {belowPoints?.description1}
          </p>
        </div>
        <br />




        <br />
        <div className="container-fluid" style={{ background: "#F4FCFF" }}>
          <div>
            <ProForm
              proId={39}
              image="/media/OnlineInquiry/yarn-inquiry-form-bg.jpg"
              inquiryForm
              info={params?.product_title}
              tag="Send us your inquiry we will quote you best price"
            />
          </div>
        </div>
      </section>
    </>
  );
}
